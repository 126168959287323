import { clsx } from 'clsx';
import {
  ChangeEvent,
  FocusEvent,
  DetailedHTMLProps,
  InputHTMLAttributes,
  ReactElement,
  Ref,
} from 'react';
import { InputStateIndicator } from '../_elements/InputStateIndicator';
import { InputStateVariant } from '../InputProps.types';

import styles from './textInput.module.scss';

export type TextInputProps = Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'type'
> & {
  name?: string;
  className?: string;
  state?: InputStateVariant;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement, Element>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement, Element>) => void;
  _ref?: Ref<HTMLInputElement>;
} & HTMLDataAttributes;

/**
 * TextInput component
 */
export const TextInput = ({
  name = 'text-input',
  value,
  defaultValue,
  className,
  state = 'neutral',
  _ref,
  ...props
}: TextInputProps): ReactElement => {
  return (
    <span className={clsx(styles.textInput, className)} data-state={state}>
      <input
        type={'text'}
        ref={_ref}
        id={name}
        name={name}
        value={value}
        defaultValue={defaultValue}
        {...props}
      />

      <InputStateIndicator state={state} className={styles.stateIndicator} />
    </span>
  );
};
