import type { InitOptions } from 'i18next';
import { en } from '../locales/en';
import { fi } from '../locales/fi';
import { nb } from '../locales/nb';
import { sv } from '../locales/sv';

export const namespace = 'ProductInsurance';

const resources = {
  en: {
    [namespace]: en,
  },
  fi: {
    [namespace]: fi,
  },
  nb: {
    [namespace]: nb,
  },
  sv: {
    [namespace]: sv,
  },
} as const;

const supportedLngs = Object.keys(resources) as Array<keyof typeof resources>;

export const i18nConfig: InitOptions = {
  resources,
  // lng: 'sv',
  // fallbackLng: supportedLngs[0],
  interpolation: {
    escapeValue: false,
  },
  ns: [namespace],
  defaultNS: namespace,
  supportedLngs,
};
