import {
  DataAccessProvider,
  ServicesBaseURL,
} from '@axo/shared/data-access/provider';
import { ReactNode } from 'react';
import { QueryClient } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Disable refetching on window focus, as there is no risk of mutating the instance in another session
      refetchOnWindowFocus: false,
    },
  },
});

type DataAccessProviderFromLibsProps = {
  children: ReactNode;
  apiUrl: string;
};

export const DataAccessProviderFromLibs = ({
  children,
  apiUrl,
}: DataAccessProviderFromLibsProps) => {
  const servicesURL: ServicesBaseURL = {
    api: apiUrl,
  };

  return (
    <DataAccessProvider queryClient={queryClient} url={servicesURL}>
      {children}
    </DataAccessProvider>
  );
};
