import { create } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { config } from './view-state-machine.config';

import {
  ViewArea,
  ViewSequenceConfigType,
  ViewStateMachine,
  ViewStateMachineState,
} from './view-state-machine.types';

const initialState: ViewStateMachineState = {
  currentSequenceStep: 0,
  currentFocusArea: null,
  sequence: null,
  areas: null,
  isMainView: false,
};

const useViewStateMachine = create<ViewStateMachine>()(
  subscribeWithSelector(
    devtools(
      (set, get) => ({
        ...initialState,

        setSequence: (type: ViewSequenceConfigType) =>
          set(
            () => ({
              sequence: config[type],
              currentSequenceStep: 0,
              areas: config[type][0] || null,
              isMainView: config[type].length === 0,
            }),
            undefined,
            'view/setSequence'
          ),

        proceed: () =>
          set(
            (state) => {
              if (!state.sequence) return state;

              const nextStep = Math.min(
                state.currentSequenceStep + 1,
                state.sequence.length - 1
              );
              return {
                currentSequenceStep: nextStep,
                isMainView: nextStep === state.sequence.length - 1,
                areas: state.sequence[nextStep],
              };
            },
            undefined,
            'view/proceed'
          ),

        focus: (area: ViewArea | null) =>
          set(
            {
              currentFocusArea: area,
            },
            undefined,
            'view/focus'
          ),
      }),
      { name: 'content-layout/view-state-machine' }
    )
  )
);

export { useViewStateMachine };
