import type { AccountNumberLabels, Term } from './content.types';
import type { en } from './en';

export const nb: Partial<typeof en> = {
  product: {
    name: 'Axo utgiftsforsikring',
  },
  amountSelector: {
    intro: 'Velg beløpet du ønsker å få utbetalt månedlig i tilfelle en skade',
    labels: {
      currency: 'kr',
      month: 'måne',
      premium: 'Månedlig kostnad',
      payout: 'Betales i tilfelle skade',
      payoutInfo:
        'Du kan få erstatning fra det øyeblikket forsikringen trer i kraft og karenstiden er over',
    },
  },
  navigation: {
    intro: 'Ønsker du å tegne en forsikring?',
    next: {
      INTRO: 'Ja, vær så god',
      BUY: 'Kjøp forsikring',
      SIGN: 'Signer skjemaet',
      PAYMENT_AUTHORIZE: 'Signering',
    },
    skip: 'Nei, gå til sitat',
    disable: 'Nei takk.',
    enable: 'Les mer om forsikringen',
  },
  steps: {
    intro: {
      usps: [
        'Stønad <b>{{insuredAmount}}/måned</b> ved arbeidsledighet og/eller langtidssykdom i opptil 12 måneder',
        'Bidrar til utgifter som bolig, mat, drivstoff og lån',
        'Engangserstatning <b>100 000 kr</b> for visse alvorlige sykdommer',
        'Engangserstatning <b>50 000 kr</b> ved dødsfall som følge av sykdom eller ulykke',
      ],
    },
    buy: {
      faqs: [
        {
          title: 'Hvordan vet jeg om forsikringen passer for mine behov?',
          body:
            '<b>Forbrukernes krav og behov</b><br />' +
            '<p>Hovedbeskyttelsen i forsikringen er en månedlig utbetaling ved ufrivillig arbeidsledighet eller sykefravær som følge av sykdom eller ulykke. Den er først og fremst utformet for å imøtekomme kravene og behovene til forbrukere som kan ha vanskeligheter med å dekke utgiftene sine, eller som ønsker større økonomisk trygghet i tilfelle ufrivillig arbeidsledighet eller sykefravær.</p>' +
            '<p>Vi anbefaler at du bruker følgende fire trinn for å avgjøre om forsikringen er noe for deg:</p>' +
            '<ol>' +
            '<li>Lag et budsjett for å få oversikt over hvilke månedlige utgifter du ikke kan kutte ned på hvis du blir ufrivillig arbeidsledig eller sykmeldt.</li>' +
            '<li>Tenk over hvilke andre inntekter du ville hatt i en slik situasjon, for eksempel dagpenger fra trygdeetaten, arbeidsledighetstrygd, andre forsikringer, sykepenger fra trygdeetaten, arbeidsgiveren din eller andre forsikringer, samt oppsparte midler. Vurder også om du føler deg trygg på nivået på den totale alternative månedsinntekten din.</li>' +
            '<li>Hvis svaret på spørsmålet ovenfor er «nei», bør du vurdere om du ønsker å tegne en forsikring som gir deg mulighet til å øke den totale alternative månedsinntekten din i opptil 12 måneder i tilfelle ufrivillig arbeidsledighet eller sykefravær.</li>' +
            '<li>Hvis svaret på spørsmålet ovenfor er «ja», kan budsjettet hjelpe deg med å velge et passende forsikringsbeløp per måned. Husk at den maksimale utbetalingen fra forsikringen ved ufrivillig arbeidsledighet kan være opptil 60 % av den gjennomsnittlige nettolønnen din de siste tre månedene før oppsigelsen.</li>' +
            '</ol>' +
            '<p>Forsikringen omfatter også dekning ved visse alvorlige sykdommer og gir et engangsbeløp på 100 000 kronor. Ved dødsfall på grunn av sykdom eller ulykke utbetales et engangsbeløp på 50 000 kronor til dødsboet, som for eksempel kan brukes til å dekke begravelsesutgifter.</p>',
        },
        {
          title: 'Detaljerte dokumenter',
          links: [
            {
              label: 'Faktaark om produktet',
              href: `/insurance.no/docs/InsuranceExpenses.pdf`,
              icon: 'file-pdf-solid',
            },
            {
              label: 'Informasjon før kjøp',
              href: `/insurance.no/docs/AdvanceInformation.pdf`,
              download:
                'Forhåndsinformasjon-Axo_Finans_Utgiftsforsikring_2024-03-18.pdf',
              icon: 'file-pdf-solid',
            },
          ],
        },
      ],
      salesTerms: {
        intro: 'Kontroller følgende',
        terms: <Term[]>[
          {
            name: 'general',
            label:
              'Jeg oppfyller kravene nedenfor og bekrefter at jeg har lest og akseptert <a href="/insurance.no/docs/TermsAndConditions.pdf" download="Vilkår og betingelser.pdf">Forkopsinformati-villkor og de fullstendige vilkårene og betingelsene</a>.',
            sub: [
              {
                name: 'age',
                label:
                  'er i alderen 18-65 år, er folkeregistrert i Sverige og er omfattet av svensk sosialforsikring',
              },
              {
                name: 'employment',
                label:
                  'har en fast jobb på minst 16 timer per uke, uten kjennskap til forestående arbeidsledighet eller oppsigelse',
              },
              {
                name: 'health',
                label:
                  'er fullt arbeidsdyktig, uten kjennskap til alvorlig sykdom eller potensielt sykefravær',
              },
              {
                name: 'coverage',
                label:
                  'er klar over at forsikringen kun dekker arbeidsledighet, sykdom eller skade som inntreffer i forsikringstiden',
              },
            ],
          },
          {
            name: 'loan',
            label:
              'Jeg er innforstått med at trygdeforsikringen gjelder uansett om jeg får innvilget lån eller ikke.',
          },
        ],
      },
    },
    sign: {
      accountNumber: {
        title: 'Kontodetaljer',
        description:
          'Ettersom du har valgt Trygghetsforsikring, vil forsikringspremien bli trukket månedlig fra denne kontoen via avtalegiro.',
        note: 'Kontonummeret må være en transaksjonskonto (ikke en sparekonto) og tilhøre deg personlig.',
        form: <AccountNumberLabels>{
          clearingNumber: {
            name: 'clearingNumber',
            label: 'Clearingnummer',
            placeholder: '1100',
            validation: {
              required: 'Dette feltet er obligatorisk',
              invalid:
                'Clearingnumre må kun inneholde sifre og være nøyaktig 4 tegn lange, og må være mellom 1100 og 9969',
            },
          },
          accountNumber: {
            name: 'kontonummer',
            label: 'Kontonummer',
            placeholder: '0123456789',
            validation: {
              required: 'Dette feltet er obligatorisk',
              invalid: 'Kontonummeret må være mellom 7 og 10 siffer langt',
            },
          },
        },
      },
      signInsurance: {
        intro: 'Signering',
        term: <Term>{
          name: 'sign',
          label: `Jag har läst och godkänner <a href="/insurance.no/docs/AdvanceInformation.pdf" download="FORHÅNDSINFORMASJON.pdf">villkor för autogiro och hantering av personuppgifter</a>.`,
        },
        note: 'Jeg bekrefter kjøpet av trygdeforsikringen og avtalegirofullmakten ved å signere med BankID.',
      },
    },
    paymentMandate: {
      title: 'Signer avtalen for avtalegirobetalinger',
      message: {
        warn: 'Vi kunne ikke fullføre forsikringsbetalingen din fordi du avbestilte. Prøv igjen hvis du vil fortsette.',
        error:
          'Vi kunne ikke fullføre forsikringsbetalingen din på grunn av en teknisk feil. Vennligst prøv igjen.',
      },
    },
    confirmation: {
      title: 'Gratulerer med den nye utgiftsforsikringen!',
      description:
        'Du vil få tilsendt en e-post med informasjon om forsikringen din innen 24 timer. Hvis du har spørsmål, er du velkommen til å kontakte oss.',
    },
  },
};
