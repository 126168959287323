import clsx from 'clsx';
import { CSSProperties, ReactNode } from 'react';
import styles from './grid.module.scss';

type ResponsiveSizes = { s: number; m: number; l: number };

/**
 * @wip
 */

type GridProps = {
  rows?: number | ResponsiveSizes | 'auto';
  columns?: number | ResponsiveSizes | 'auto';
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
};

export const Grid = ({
  children,
  rows = 1,
  columns = 1,
  className,
  style = {},
}: GridProps) => {
  const mapGridValueToStyle = (
    value: number | ResponsiveSizes | 'auto',
    propPrefix: string,
  ) =>
    typeof value === 'object' && value !== null
      ? {
          [`--s-${propPrefix}`]: value.s,
          [`--m-${propPrefix}`]: value.m,
          [`--l-${propPrefix}`]: value.l,
        }
      : { [`--${propPrefix}`]: value };

  return (
    <section
      className={clsx(styles.grid, className)}
      style={{
        ...mapGridValueToStyle(rows, 'grid-rows'),
        ...mapGridValueToStyle(columns, 'grid-columns'),
        ...style,
      }}
    >
      {children}
    </section>
  );
};
