import { ApplicationProgressContext } from '@axo/mypage/feature';
import { ProgressBarWithDescription as UiProgressBarWithDescription } from '@axo/ui-feature/components/ProgressBarWithDescription';
import { ContentLayout } from '@axo/ui-feature/features/MyPageLayout';
import { useContentLayout } from '@axo/ui-feature/features/MyPageLayout/useContentLayout';
import { useContext } from 'react';

export const ProgressBarWithDescription = () => {
  const { state: progressBarState } = useContext(ApplicationProgressContext);
  const { isMainView } = useContentLayout();

  return (
    <ContentLayout.Area area={'progress'}>
      {!isMainView || progressBarState.isInProgress ? (
        <UiProgressBarWithDescription {...progressBarState} />
      ) : null}
    </ContentLayout.Area>
  );
};
