import { useCallback, useEffect, useRef, useState } from 'react';
import { addClientToDom } from './add-client-to-dom';
import type {
  AssentlyServiceConfig,
  AssentlyServiceResponse,
  ClientEmbedUrl,
} from './assently-service.types';

import { config as seSignConfig } from './config/assently-service.se.sign.config';

type UseAssentlyServiceParams = {
  clientEmbedUrl?: ClientEmbedUrl;
  isProduction?: boolean;
  onResponse: (response: AssentlyServiceResponse) => void;
};

type AssentlyServiceInitializeParams = {
  token: string;
  data?: Pick<AssentlyServiceConfig['sign'], 'title' | 'data'>;
  config?: AssentlyServiceConfig;
};

type UseAssentlyService = {
  isLoaded: boolean;
  isInitialized: boolean;
  isStarted: boolean;
  isSigned: boolean;
  isClosed: boolean;
  isCancelled: boolean;
  addClient: (targetDocument?: Document) => void;
  initialize: (params: AssentlyServiceInitializeParams) => void;
  start: () => void;
  close: () => void;
};

export const useAssentlyService = ({
  clientEmbedUrl,
  isProduction = false,
  onResponse,
}: UseAssentlyServiceParams): UseAssentlyService => {
  const coreIdClientRef = useRef<typeof window.coreid_client | null>(null);
  const targetDocRef = useRef<Document | null>(null);

  // TODO optimize with reducer
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isStarted, setIsStarted] = useState<boolean>(false);
  const [isClosed, setIsClosed] = useState<boolean>(false);
  const [isCancelled, setIsCancelled] = useState<boolean>(false);
  const [isSigned, setIsSigned] = useState<boolean>(false);

  const onLoad = useCallback(() => {
    setIsLoaded(true);

    if (targetDocRef.current)
      coreIdClientRef.current =
        targetDocRef.current.defaultView!.coreid_client || null;
  }, []);

  const addClient = useCallback(
    (targetDocument: Document = document) => {
      targetDocRef.current = targetDocument;
      addClientToDom({
        clientEmbedUrl: clientEmbedUrl
          ? clientEmbedUrl
          : isProduction
          ? 'https://coreid.assently.com/embed/coreid.js'
          : 'https://coreid-test.assently.com/embed/coreid.js',
        doc: targetDocRef.current,
        onLoad,
      });
    },
    [clientEmbedUrl, onLoad, isProduction]
  );

  const start = useCallback(() => {
    if (!isInitialized || !coreIdClientRef.current) {
      console.error('Assently client is not initialized');
      return;
    }

    coreIdClientRef.current.start();
    setIsCancelled(false);
    setIsClosed(false);
    setIsStarted(true);
    // console.log('Assently client started');
  }, [isInitialized]);

  const close = useCallback(() => {
    if (!isInitialized || !coreIdClientRef.current) {
      console.error('Assently client is not initialized');
      return;
    }

    coreIdClientRef.current.close();
    setIsStarted(false);
    setIsClosed(true);
    // console.log('Assently client closed');
  }, [isInitialized]);

  const onClientCallback = useCallback(
    (response: AssentlyServiceResponse) => {
      onResponse(response);

      // console.log('Assently callback response', response.type, response);

      if (['cancelled'].includes(response.type)) {
        setIsCancelled(true);
      }
      if (['signed'].includes(response.type)) {
        setIsSigned(true);
      }
      if (['signed', 'cancelled'].includes(response.type)) {
        close();
      }
    },
    [onResponse, close]
  );

  // ensure the callback used by `coreIdClientRef.current.init` has the latest state
  const onClientCallbackRef = useRef<typeof onClientCallback>(onClientCallback);
  useEffect(() => {
    onClientCallbackRef.current = onClientCallback;
  }, [onClientCallback]);

  const initialize = useCallback(
    ({
      token,
      data = seSignConfig.sign,
      config = seSignConfig,
    }: AssentlyServiceInitializeParams) => {
      if (!isLoaded || !coreIdClientRef.current) {
        console.error('Assently client is not loaded');
        return;
      }

      if (isInitialized) {
        console.debug('Assently client already initialized');
        return;
      }

      coreIdClientRef.current.init({
        config: { ...config, sign: { ...config.sign, ...data } },
        token,
        callback: (response: AssentlyServiceResponse) =>
          onClientCallbackRef.current?.(response),
      });

      setIsInitialized(true);
      console.log('Assently client initialized');
    },
    [isLoaded, isInitialized]
  );

  return {
    addClient,
    isLoaded,
    isInitialized,
    isStarted,
    isSigned,
    isClosed,
    isCancelled,
    initialize,
    start,
    close,
  };
};
