import { InitOptions } from 'i18next';
import { en } from './en';
import { sv } from './sv';

export const namespace = 'AccountNumber';

const resources = {
  en: {
    [namespace]: en,
  },
  sv: {
    [namespace]: sv,
  },
} as const;

const supportedLngs = Object.keys(resources) as Array<keyof typeof resources>;

export const i18nConfig: InitOptions = {
  resources,
  lng: 'sv', //supportedLngs[0],
  fallbackLng: 'sv', //supportedLngs[0],
  interpolation: {
    escapeValue: false,
  },
  ns: [namespace],
  defaultNS: namespace,
  supportedLngs,
};
