import { useAnalytics } from '@axo/shared/services/analytics';
import { useCallback } from 'react';

export type UseUserAnalytics = ReturnType<typeof useUserAnalytics>;

export const useUserAnalytics = () => {
  const { track } = useAnalytics();

  const trackInitiated = useCallback(
    () => track({ event: 'Insurance Initiated' }),
    [track]
  );
  const trackEligible = useCallback(
    (isEligible: boolean) =>
      track({
        event: 'Insurance Eligibility Checked',
        params: { isEligible },
      }),
    [track]
  );
  const trackStepViewed = useCallback(
    (step: number, name: string) =>
      track({ event: `Insurance Step ${step} Viewed`, params: { name } }),
    [track]
  );
  const trackCompleted = useCallback(
    (amount: number) =>
      track({
        event: 'Insurance Completed',
        params: { amount },
      }),
    [track]
  );
  const trackDeclined = useCallback(
    (stepName: string) =>
      track({ event: 'Insurance Declined', params: { stepName } }),
    [track]
  );
  const trackAmount = useCallback(
    (amount: number, stepName: string) =>
      track({
        event: 'Insurance Amount Interacted',
        params: { amount, stepName },
      }),
    [track]
  );
  const trackPayment = useCallback(
    (state: 'Initiated' | 'Cancelled' | 'Failed' | 'Completed') =>
      track({ event: `Insurance Payment ${state}` }),
    [track]
  );

  return {
    trackInitiated,
    trackEligible,
    trackStepViewed,
    trackCompleted,
    trackDeclined,
    trackAmount,
    trackPayment,
  };
};
