import { z } from 'zod';

export const AccountNumberSeSchema = z.object({
  clearingNumber: z
    .string()
    .length(4, 'Clearing number must be exactly 4 characters long')
    .refine(
      (val) => {
        const num = parseInt(val, 10);
        return num >= 1100 && num <= 9969;
      },
      { message: 'Clearing number must be between 1100 and 9969' }
    ),
  accountNumber: z
    .string()
    .min(7, 'Account number must be at least 7 characters long')
    .max(10, 'Account number must be at most 10 characters long')
    .regex(/^\d{7,10}$/, 'Account number must be between 7 and 10 digits long'),
});

export const AccountNumberSeConstraints = {
  clearingNumber: {
    maxLength: 4,
    placeholder: '1100',
  },
  accountNumber: {
    minLength: 7,
    maxLength: 10,
    placeholder: '0123456789',
  },
};
