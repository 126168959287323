import {
  useCreateInsuranceBankIdMandateFinalize,
  // TODO Add other implementations
} from '@axo/shared/data-access/hooks';
import type {
  PaymentMandateType,
  CreateBankIdPaymentMandateFinalizeRequest,
} from '@axo/shared/data-access/types/insurance_payment';

/***/

type UseCreateInsurancePaymentMandateCompleteApiImpl =
  | typeof useCreateInsuranceBankIdMandateFinalize;
// TODO Add other implementations

/**
 * Factory for different PaymentMandateType implementations
 *
 * used by `useCreateInsurancePaymentMandateComplete` to handle different `paymentMandateType`s
 *
 * @note `kid` is a query, `card` `bs` and `bankid` is a mutation
 * (aka. ask back-end to switch `kid` to a mutation to streamline this)
 * – also, be consistent in finalize w/ payload, vs patch update and finalize – call it complete ?
 */

export const useCreatePaymentMandateCompleteApiImpl = (
  paymentMandateType: PaymentMandateType = 'card'
) => {
  const implMap: Record<
    PaymentMandateType,
    UseCreateInsurancePaymentMandateCompleteApiImpl | null
  > = {
    bankid: useCreateInsuranceBankIdMandateFinalize,
    bs: null,
    card: useCreateInsuranceBankIdMandateFinalize, // TODO
    kid: useCreateInsuranceBankIdMandateFinalize, // TODO
  };

  if (!implMap[paymentMandateType]) {
    // throw new Error(`Unsupported payment mandate type: ${paymentMandateType}`);
    console.error(`Unsupported payment mandate type: ${paymentMandateType}`);
  }

  return implMap[
    paymentMandateType
  ] as UseCreateInsurancePaymentMandateCompleteApiImpl;
};

/**
 * Helper
 */

type PaymentMandateDto = CreateBankIdPaymentMandateFinalizeRequest;

type DtoCreator = (data: CreateInputData) => PaymentMandateDto;

type CreateInputData = {
  MandateID: string;
  PolicyID: string;
  amount: number;
  //
  paymentMandateProviderResponse: Record<string, any>;
};

const createBankIdDto = (
  data: CreateInputData
): CreateBankIdPaymentMandateFinalizeRequest => ({
  MandateID: data.MandateID,
  PolicyID: data.PolicyID,
  Amount: data.amount,
  AssentlyToken: data.paymentMandateProviderResponse.token,
});

const dtoCreators: Record<PaymentMandateType, DtoCreator | null> = {
  bankid: createBankIdDto,
  bs: null,
  card: null,
  kid: null,
};

export const createPaymentMandateCompleteDto = (
  paymentMandateType: PaymentMandateType = 'card',
  data: any
) => {
  const dtoCreator = dtoCreators[paymentMandateType];

  if (!dtoCreator) {
    throw new Error(`Unsupported payment mandate type: ${paymentMandateType}`);
  }

  return dtoCreator(data);
};
