import { Stack } from '@axo/ui-core/components/layout/item';
import { Text } from '@axo/ui-core/components/typography';
import { clsx } from 'clsx';
import { ReactElement } from 'react';

import styles from './loanQuoteFooter.module.scss';

export type LoanQuoteFooterProps = {
  applicationId?: string;
  labels: {
    applicationId: string;
    loanExample?: string;
  };
  className?: string;
} & HTMLDataAttributes;

export const LoanQuoteFooter = ({
  applicationId,
  labels,
  className,
  ...props
}: LoanQuoteFooterProps): ReactElement => {
  return (
    <Stack className={clsx(styles.loanQuoteFooter, className)} {...props}>
      {applicationId ? (
        <Text size={'m'}>
          <b>{`${labels.applicationId} ${applicationId}`}</b>
        </Text>
      ) : null}
      {labels.loanExample ? (
        <Text size={'xs'}>{labels.loanExample}</Text>
      ) : null}
    </Stack>
  );
};
