import type { Variants } from 'motion/react';

const durationHeight = 0.3;
const durationOpacity = 0.2;

export const areaAnimations: Variants = {
  hidden: {
    height: 0,
    opacity: 0,
    visibility: 'hidden',
    transition: {
      height: {
        duration: durationHeight,
        delay: durationOpacity,
        ease: 'easeInOut',
      },
      opacity: { duration: durationOpacity },
    },
    transitionEnd: {
      visibility: 'hidden',
    },
  },
  visible: {
    height: 'auto',
    opacity: 1,
    visibility: 'visible',
    transition: {
      height: { duration: durationHeight, ease: 'easeInOut' },
      opacity: { duration: durationOpacity, delay: durationHeight },
    },
  },
  exit: {
    height: 0,
    opacity: 0,
    transition: {
      height: {
        duration: durationHeight,
        delay: durationOpacity,
        ease: 'easeInOut',
      },
      opacity: { duration: durationOpacity },
    },
    transitionEnd: {
      visibility: 'hidden',
    },
  },
};
