import { insurance_payment_api } from '@axo/shared/data-access/api';
import { useAPI } from '@axo/shared/data-access/provider';
import { useMutation, useQueryClient } from 'react-query';
import { insuranceCustomerKeys } from '../cache-keys/insuranceCustomerKeys';

export function useCreateInsuranceBankIdMandate() {
  const client = useQueryClient();
  const api = useAPI();

  return useMutation(insurance_payment_api.createBankIdMandate(api), {
    onSuccess: (req) => {
      const cacheKey = insuranceCustomerKeys.root({
        insuranceID: req.InsuranceID,
        customerID: req.CustomerID,
        marketCountry: req.MarketCountry,
      });

      client.invalidateQueries(cacheKey);
    },
  });
}
