import { insurance_payment_api } from '@axo/shared/data-access/api';
import { useAPI } from '@axo/shared/data-access/provider';
import { useMutation, useQueryClient } from 'react-query';
import { insurancePaymentKeys } from '../cache-keys/insurancePaymentKeys';

export function useCreateInsuranceBankIdMandateFinalize() {
  const client = useQueryClient();
  const api = useAPI();

  return useMutation(insurance_payment_api.createBankIdMandateFinalize(api), {
    onSuccess: (_data, variables) => {
      const cacheKey = insurancePaymentKeys.root({
        insurancePaymentID: variables.MandateID,
      });

      client.invalidateQueries(cacheKey);
    },
  });
}
