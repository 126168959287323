import { insurance_payment } from '@axo/shared/data-access/types';
import { IAPI } from '../../lib/models/api';
import {
  createMandate,
  findMandates,
  getMandate,
  resourceUri,
} from './mandate.utils';

/**
 * bankid mandate
 *
 * https://gitlab.com/a2755/services/insurance_payment_mandate/-/blob/main/api/openapi.json
 */

const mandateType: insurance_payment.PaymentMandateType = 'bankid';

export const createBankIdMandate = createMandate<
  insurance_payment.CreateBankIdPaymentMandateRequest,
  insurance_payment.BankIdPaymentMandate
>(mandateType);

export const findBankIdMandates = findMandates(mandateType);

export const getBankIdMandate =
  getMandate<insurance_payment.BankIdPaymentMandate>(mandateType);

export const createBankIdMandateFinalize = ({
  url: { service: baseURL },
  token,
}: IAPI) => {
  return async function create(
    request: insurance_payment.CreateBankIdPaymentMandateFinalizeRequest
  ): Promise<void> {
    const url = new URL(
      `${resourceUri}/${mandateType}/${request.MandateID}/finalize`,
      baseURL
    );
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(request),
    });
    if (!response.ok) {
      throw new Error(`Network response was not OK: ${response.status}`);
    }
  };
};
