import { useTranslation } from '@axo/mypage/util/translation';
import {
  LoanQuoteFooter as UiLoanQuoteFooter,
  LoanQuoteFooterProps as UiLoanQuoteFooterProps,
} from '@axo/ui-feature/components/LoanQuoteFooter';
import { ReactElement } from 'react';

export type LoanQuoteFooterProps = {
  applicationId?: string;
};

export const LoanQuoteFooter = ({
  applicationId,
}: LoanQuoteFooterProps): ReactElement => {
  const { t } = useTranslation();

  const labels: UiLoanQuoteFooterProps['labels'] = {
    applicationId: t('Your application ID is'),
  };

  return <UiLoanQuoteFooter applicationId={applicationId} labels={labels} />;
};
