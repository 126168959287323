import { Stack } from '@axo/ui-core/components/layout/item';
import { useMemo } from 'react';
import { ConfigService } from '../../../config/ConfigService';
import { AccountNumberForm } from './AccountNumberForm';
import { SignInsuranceForm } from './SignInsuranceForm';
import styles from './signScreen.module.scss';

export const SignScreen = () => {
  const hasAccountNumberForm = useMemo(
    () => ConfigService.config.options?.hasAccountNumberForm ?? false,
    []
  );

  return (
    <Stack className={styles.signScreen}>
      {hasAccountNumberForm ? <AccountNumberForm /> : null}
      <SignInsuranceForm />
    </Stack>
  );
};
