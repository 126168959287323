import type { AssentlyServiceConfig } from '../assently-service.types';

export const config: AssentlyServiceConfig = {
  allowedEids: ['se-bankid'],
  mode: 'sign',
  language: 'sv',
  location: 'se',
  provider: 'se-bankid',
  sign: {
    type: 'text',
    title: 'payment mandate',
    data: '',
  },
  providerSettings: {
    'se-bankid': {
      autoStart: true,
      defaultToQR: true,
    },
  },
  showTitle: false,
};
