import {
  useCreateInsuranceRequest as useCreateInsuranceRequestApi,
  useInsuranceRequest as useInsuranceRequestApi,
} from '@axo/shared/data-access/hooks';
import type { insurance_request } from '@axo/shared/data-access/types';
import { useEffect } from 'react';
import { getUpdateRequestState } from '../../store/data-store.utils';
import { useDataStore } from '../../store/useDataStore';
import { getSourceAttribution } from '../analytics/source-attribution-tracking';

/**
 * async creation process
 * `create` → `get` w/ polling till conditions are met
 * react-query hook does the polling / refetch
 */

export const useCreateInsuranceRequest = () => {
  const { insuranceRequest, loan } = useDataStore((state) => state.application);
  const { updateRequestState } = getUpdateRequestState('insuranceRequest');

  const {
    mutateAsync: createInsuranceRequest,
    ...resultCreateInsuranceRequest
  } = useCreateInsuranceRequestApi();

  const createInsurance = async () => {
    updateRequestState({ status: 'loading' });

    const insuranceRequestDto: insurance_request.Create = {
      CustomerID: loan?.CustomerID || '',
      PersonID: loan?.PersonID || '',
      LoanApplicationID: loan?.ID || '',
      MarketCountry: loan?.MarketCountry || '',
      Source: getSourceAttribution(),
    };
    await createInsuranceRequest(insuranceRequestDto);
  };

  useEffect(() => {
    if (insuranceRequest.status === 'requested') createInsurance();
  }, [insuranceRequest.status]);

  const { data: insuranceRequestData, ...insuranceRequestResult } =
    useInsuranceRequestApi(resultCreateInsuranceRequest.data?.ID);

  // TODO insurance request can be denied because they have an existing one, so show an error on step 3
  useEffect(() => {
    if (insuranceRequestData?.PolicyID)
      updateRequestState({ status: 'completed', data: insuranceRequestData });
  }, [insuranceRequestResult.isSuccess, insuranceRequestData]);
};
