import React, { useEffect, useRef } from 'react';

type IframeWrapperProps = {
  onLoad: (iframe: HTMLIFrameElement) => void;
  onMessage: (message: any) => void;
  className?: string;
};

export const IframeWrapper = ({
  onLoad,
  onMessage,
  className,
}: IframeWrapperProps) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe?.contentWindow) iframe.onload = () => onLoad(iframe);
  }, [onLoad]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const iframe = iframeRef.current;
      if (
        iframe &&
        event.source === iframe.contentWindow &&
        event.origin === window.location.origin
      )
        onMessage(event.data);
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [onMessage]);

  const getIframeContent = () => `
    <!DOCTYPE html>
    <html>
    <head>
      <meta charset='UTF-8'>
      <meta name='viewport' content='width=device-width, initial-scale=1.0'>
    </head>
    <body />
    </html>
  `;

  const iframeContent = getIframeContent();

  return (
    <iframe
      ref={iframeRef}
      srcDoc={iframeContent}
      className={className}
      title="Payment Provider IFrame"
    />
  );
};
