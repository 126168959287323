import { DataAccessContext } from '@axo/shared/data-access/provider';
import { useContext, useEffect } from 'react';
import { ConfigService } from '../../config/ConfigService';
import { useDataStore } from '../../store/useDataStore';
import { useCreateInsurancePaymentMandate } from './useCreateInsurancePaymentMandate';
import { useCreateInsurancePaymentMandateComplete } from './useCreateInsurancePaymentMandateComplete';
import { useCreateInsuranceRequest } from './useCreateInsuranceRequest';
import { useInsurancePolicy } from './useInsurancePolicy';
import { useIsEligible } from './useIsEligible';
import { useLoanApplication } from './useLoanApplication';

export const useInitializeApiService = () => {
  // sync server-side state

  useLoanApplication();
  useIsEligible();
  useCreateInsuranceRequest();
  useInsurancePolicy();
  useCreateInsurancePaymentMandate({
    paymentMandateType: ConfigService.config.paymentMandateType,
  });
  useCreateInsurancePaymentMandateComplete({
    paymentMandateType: ConfigService.config.paymentMandateType,
  });

  // set auth on data access context when token becomes available on client-side state

  const { auth } = useDataStore();
  const { dispatch } = useContext(DataAccessContext);

  useEffect(() => {
    if (auth.token) {
      dispatch({
        type: 'Set auth',
        scope: { parentType: 'user' },
        payload: {
          JWT: auth.token,
        },
      });
    }
  }, [auth.token]);
};
