import type { insurance_payment } from '@axo/shared/data-access/types';
import { useEffect } from 'react';
import { getUpdateRequestState } from '../../store/data-store.utils';
import { useDataStore } from '../../store/useDataStore';
import {
  createPaymentMandateCompleteDto,
  useCreatePaymentMandateCompleteApiImpl,
} from './useCreateInsurancePaymentMandateCompleteApi.factory';
import { useInsurancePaymentMandateApiImpl } from './useInsurancePaymentMandateApi.factory';

type UseCreateInsurancePaymentMandateCompleteProps = {
  paymentMandateType: insurance_payment.PaymentMandateType;
};

/**
 * finalize payment mandate w/ external provider data
 * `create` → `get` latest version of `insurancePaymentMandate`
 */

export const useCreateInsurancePaymentMandateComplete = ({
  paymentMandateType,
}: UseCreateInsurancePaymentMandateCompleteProps) => {
  const {
    insurancePaymentMandate,
    insurancePaymentMandateComplete,
    insurance,
    input,
  } = useDataStore((state) => state.application);
  const { updateRequestState } = getUpdateRequestState(
    'insurancePaymentMandateComplete'
  );

  /** create */

  const useCreateInsurancePaymentMandateCompleteApi =
    useCreatePaymentMandateCompleteApiImpl(paymentMandateType);

  const {
    mutateAsync: createInsurancePaymentMandateComplete,
    data: createInsurancePaymentMandateCompleteData,
    ...createInsurancePaymentMandateCompleteResult
  } = useCreateInsurancePaymentMandateCompleteApi();

  const createPaymentMandateComplete = async () => {
    updateRequestState({ status: 'loading' });

    const paymentMandateDto = createPaymentMandateCompleteDto(
      paymentMandateType,
      {
        MandateID: insurancePaymentMandate.data?.ID,
        PolicyID: insurance.data?.ID,
        ...input,
      }
    );

    await createInsurancePaymentMandateComplete(paymentMandateDto as never);
  };

  useEffect(() => {
    if (insurancePaymentMandateComplete.status === 'requested')
      createPaymentMandateComplete();
  }, [insurancePaymentMandateComplete.status]);

  /** get */

  const useInsurancePaymentMandateApi =
    useInsurancePaymentMandateApiImpl(paymentMandateType);

  const {
    data: insurancePaymentMandateData,
    ...insurancePaymentMandateResult
  } = useInsurancePaymentMandateApi(insurancePaymentMandate.data?.ID, false);

  useEffect(() => {
    if (insurancePaymentMandateData?.Status === 'completed')
      updateRequestState({
        status: 'completed',
        data: insurancePaymentMandateData,
      });
  }, [insurancePaymentMandateResult.isSuccess, insurancePaymentMandateData]);
};
