import { Modal } from '@axo/ui-core/components/Modal';
import styles from './AcceptModal.module.scss';
import { ReactNode } from 'react';
import { AcceptModalActionBar } from './AcceptModalActionBar';
import clsx from 'clsx';
import { Stack } from '@axo/ui-core/components/layout/item';

type AcceptModalProps = {
  open: boolean;
  onClose?: () => void;
  children: ReactNode;
  actionBar?: {
    title?: ReactNode;
    buttons: ReactNode;
  };
  classes?: {
    root?: string;
    content?: string;
    actionBar?: {
      root?: string;
      buttons?: string;
    };
  };
  type?: 'default' | 'info' | 'success' | 'attention' | 'warning';
};

export function AcceptModal({
  open,
  onClose,
  actionBar,
  children,
  classes,
  type = 'default',
  ...props
}: AcceptModalProps) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      className={clsx(styles.modalRoot, classes?.root)}
      data-modal-type={type}
      {...props}
    >
      <div className={styles.borderTop} />
      <Stack gap={'xs'} className={clsx(styles.content, classes?.content)}>
        {children}
      </Stack>
      {actionBar && (
        <Modal.Actions className={styles.actions}>
          <AcceptModalActionBar
            actionBar={actionBar}
            classes={{ ...classes?.actionBar }}
          />
        </Modal.Actions>
      )}
    </Modal>
  );
}
