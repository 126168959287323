import {
  useLoanApplication as useLoanApplicationFromToken,
  useLoanApplicationFromMagicToken,
} from '@axo/shared/data-access/hooks';
import { DataAccessContext } from '@axo/shared/data-access/provider';
import { useContext, useEffect } from 'react';
import { useDataStore } from '../../store/useDataStore';

export const useLoanApplication = () => {
  const { auth, application, setToken, setLoan } = useDataStore();
  const { url } = useContext(DataAccessContext);

  const { data: dataFromMagicToken, ...resultMagicToken } =
    useLoanApplicationFromMagicToken(url.api, auth.magicToken);

  useEffect(() => {
    if (dataFromMagicToken) {
      const { JWT, ...loan } = dataFromMagicToken;
      setToken(JWT);
      setLoan(loan);
    }
  }, [dataFromMagicToken]);

  const { data: dataFromToken, ...resultToken } = useLoanApplicationFromToken(
    application.loan?.ID
  );

  useEffect(() => {
    if (dataFromToken) {
      setLoan(dataFromToken);
    }
  }, [dataFromToken]);
};
