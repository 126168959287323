import { clsx } from 'clsx';
import { ReactElement, ReactNode, MouseEventHandler } from 'react';
import { Button } from '../../../Button';
import { Icon } from '../../../Icon';
import {
  InputSizeVariant,
  InputStateVariant,
} from '../../../input/InputProps.types';

import styles from './formControlValidation.module.scss';

type FormControlValidationProps = {
  children?: ReactNode;
  className?: string;
  state?: InputStateVariant;
  size?: InputSizeVariant;
  onConfirm?: MouseEventHandler<Element> | (() => void);
  onCancel?: MouseEventHandler<Element> | (() => void);
} & HTMLDataAttributes;

/**
 * FormControlValidation component
 */
export const FormControlValidation = ({
  children,
  className,
  state = 'neutral',
  size = 'small',
  onConfirm,
  onCancel,
  ...props
}: FormControlValidationProps): ReactElement => {
  return (
    <div
      className={clsx(styles.formControlValidation, className)}
      data-state={state}
      data-size={size}
      data-form-control-element="form-control-validation"
      {...props}
    >
      {typeof children === 'string' ? (
        <span dangerouslySetInnerHTML={{ __html: children }} />
      ) : (
        <span>{children}</span>
      )}

      {onConfirm && onCancel && (
        <div className={styles.confirmation}>
          <Button
            iconOnly
            variant={'ghost'}
            size={'small'}
            onClick={onCancel}
            aria-label={'cancel'}
            className={'cancel'}
          >
            <Icon name={'x'} size={'s'} />
          </Button>
          <span className={styles.divider} />
          <Button
            iconOnly
            variant={'ghost'}
            size={'small'}
            onClick={onConfirm}
            aria-label={'confirm'}
            className={'confirm'}
          >
            <Icon name={'check'} size={'s'} />
          </Button>
        </div>
      )}
    </div>
  );
};
