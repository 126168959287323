'use client';

export const getSourceAttribution = () => {
  if (typeof window === 'undefined') return 'mypage';

  const params = new URLSearchParams(window.location.search);
  const source = params.get('source') || params.get('s') || 'mypage';

  return source;
};
