import { clsx } from 'clsx';
import {
  DetailedHTMLProps,
  LabelHTMLAttributes,
  ReactElement,
  ReactNode,
} from 'react';
import { InputSizeVariant } from '../../InputProps.types';

import styles from './inputLabel.module.scss';

export type InputLabelProps = {
  children?: ReactNode;
  className?: string;
  size?: InputSizeVariant;
} & HTMLDataAttributes &
  DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>;

/**
 * InputLabel component
 */
export const InputLabel = ({
  children,
  className,
  htmlFor,
  size = 'small',
  ...props
}: InputLabelProps): ReactElement => {
  return (
    <label
      className={clsx(styles.inputLabel, className)}
      htmlFor={htmlFor}
      data-size={size}
      {...props}
    >
      {children}
    </label>
  );
};
