import { productConfig as insuranceFiConfig } from './insurance.fi.config';
import { productConfig as insuranceNoConfig } from './insurance.no.config';
import { productConfig as insuranceSeConfig } from './insurance.se.config';
import type {
  Config,
  ProductConfig,
  ProductEnvConfig,
  ProductEnvFile,
} from './product-config.types';

import type { ProductName } from './products.config';
import { products } from './products.config';

const productsConfig: Record<ProductName, ProductConfig> = {
  'insurance.no': insuranceNoConfig,
  'insurance.se': insuranceSeConfig,
  'insurance.fi': insuranceFiConfig,
};

class ConfigService {
  private static _instance: ConfigService | null = null;
  private _config: Config | null = null;
  private _envConfig: ProductEnvFile = {
    ...(typeof process !== 'undefined' ? process.env : {}),
    ...(typeof import.meta !== 'undefined' ? import.meta.env : {}),
  };

  private constructor() {}

  public static initialize(product: ProductName): void {
    if (!this._instance) {
      this._instance = new ConfigService();
      this._instance._initialize(product);
    }
  }

  public static get config(): Config {
    if (!this._instance || !this._instance._config) {
      throw new Error('ConfigService not initialized yet...');
    }
    return this._instance._config;
  }

  public static getEnv(key: keyof ProductEnvFile): string {
    if (!this._instance || !this._instance._config) {
      throw new Error('ConfigService not initialized yet...');
    }
    return this._instance._envConfig[key]!;
  }

  private _initialize(product: ProductName): void {
    if (!products.includes(product)) {
      throw new Error(
        `${product} config not supported, available options: ${products.join(
          ', '
        )}`
      );
    }

    const productConfig = productsConfig[product];

    this._config = {
      /**
       * currently based on VITE mode
       * TODO next.js env
       * @note don't base any code on this, aim to have the value in/from this config service
       * (exceptions: initialising 3rd party services)
       */
      env: this._envConfig.MODE as ProductEnvConfig['env'],
      ...productConfig,
      services: {
        apiUrl: this._envConfig.VITE_APP_API_URL
          ? this._envConfig.VITE_APP_API_URL
          : 'https://api.axo-test.io',
      },
    };

    console.info(`Config service initialized for product: ${product}`);
  }
}

export { ConfigService };
