import { useInsurancePolicy as useInsurancePolicyApi } from '@axo/shared/data-access/hooks';
import { useEffect } from 'react';
import { getUpdateRequestState } from '../../store/data-store.utils';
import { useDataStore } from '../../store/useDataStore';

export const useInsurancePolicy = () => {
  const { insuranceRequest, insurance } = useDataStore(
    (state) => state.application
  );
  const { updateRequestState } = getUpdateRequestState('insurance');

  const { data, ...insurancePolicyQueryResult } = useInsurancePolicyApi(
    insuranceRequest.data?.PolicyID
  );

  useEffect(() => {
    if (insurancePolicyQueryResult.isLoading)
      updateRequestState({ status: 'loading' });
  }, [insurancePolicyQueryResult.isLoading]);

  useEffect(() => {
    if (data) updateRequestState({ status: 'completed', data });
  }, [insurancePolicyQueryResult.isSuccess]);
};
