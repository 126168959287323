import { AllowedMarketCountry, useLocaleContext } from '@axo/mypage/feature';
import {
  ProductInsurance,
  ProductInsuranceProps,
} from '@axo/product-insurance';
import { DataAccessContext } from '@axo/shared/data-access/provider';
import { ErrorBoundary } from '@axo/shared/feature/error-boundary';
import { useFlags } from '@axo/shared/services/feature-flags';
import { features } from '@axo/shared/services/feature-flags/config/features.config';
import { useContentLayout } from '@axo/ui-feature/features/MyPageLayout/useContentLayout';
import { ReactElement, useCallback, useContext, useMemo } from 'react';

const widgetConfig: Record<AllowedMarketCountry, string> = {
  FI: 'insurance.fi',
  NO: 'insurance.no',
  SE: 'insurance.se',
};

type InsuranceProductWidgetProps = {
  applicationId?: string;
};

export const InsuranceProductWidget = ({
  applicationId,
}: InsuranceProductWidgetProps): ReactElement | null => {
  let { 'has-product-insurance-widget': hasWidget } = useFlags(features);
  hasWidget ??= false;
  const { marketCountry } = useLocaleContext();

  const { state: dataAccessState } = useContext(DataAccessContext);

  const state: ProductInsuranceProps['state'] = useMemo(
    () => ({
      auth: {
        token: dataAccessState.user.token,
      },
      application: {
        loan: {
          ID: applicationId ?? undefined,
        },
      },
    }),
    [applicationId, dataAccessState.user]
  );
  const { proceed } = useContentLayout();

  const onComplete = useCallback(() => proceed?.(), [proceed]);
  const onCancel = useCallback(() => proceed?.(), [proceed]);

  if (!hasWidget || !applicationId) return null;

  return (
    <ErrorBoundary>
      <ProductInsurance
        product={widgetConfig[marketCountry]}
        state={state}
        onComplete={onComplete}
        onCancel={onCancel}
      />
    </ErrorBoundary>
  );
};
