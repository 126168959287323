import { Button } from '@axo/ui-core/components/Button';
import { FormControl } from '@axo/ui-core/components/form/FormControl';
import { TextInput } from '@axo/ui-core/components/input/TextInput';
import { Grid, Stack } from '@axo/ui-core/components/layout/item';
import { Message } from '@axo/ui-core/components/Message';
import { Heading } from '@axo/ui-core/components/typography';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styles from './AccountNumber.module.scss';
import {
  AccountNumber,
  AccountNumberFormProps,
} from './AccountNumberForm.types';
import { namespace } from './locales/i18n.config';
import {
  AccountNumberSeConstraints,
  AccountNumberSeSchema,
} from './schema/AccountNumber.se.schema';
import { useDataStore } from './store/useDataStore';

const schema = AccountNumberSeSchema;
const constraints = AccountNumberSeConstraints;

/**
 * @fixme conceptually the query and mutation should be separated, because the cache is invalidated
 * and reloads the loan-application, therefore a false success is shortly displayed on second attempt
 *
 * basically, state glitches after first submit (out-of-scope of the one-shot update use case, but should work correctly)
 *
 * @fixme expose the form without forwardRef, the `AccountNumber` is the entry point that should have variants for the use cases
 * use case: 1. standalone form, 2. MP offer accept, 3. MP insurance (in progress)
 * question: component responsibility, retrieve data (and state, is account nr already available for MP offer accept) ?, persist data (MP offer accept, MP insurance) ?
 */

export const AccountNumberForm = forwardRef(
  (
    {
      onSubmit,
      title,
      clearingNumberLabel,
      accountNumberLabel,
      validation,
    }: AccountNumberFormProps,
    ref: Ref<{ handleSubmit: () => void }>
  ) => {
    const { t } = useTranslation(namespace);
    const { loan } = useDataStore((state) => state.application);
    const { setInput } = useDataStore();
    const [showMessage, setShowMessage] = useState(false);
    // FIXME temporary FIX for state getting out of sync (see below)
    const [hasSubmittedOnce, setHasSubmittedOnce] = useState(false);

    const {
      register,
      handleSubmit,
      formState: { errors, isSubmitted, isDirty },
      reset,
    } = useForm<AccountNumber>({
      resolver: zodResolver(schema),
      defaultValues: {
        clearingNumber: loan?.data?.AccountClearingNumber ?? undefined,
        accountNumber: loan?.data?.AccountNumber ?? undefined,
      },
    });

    useEffect(() => {
      if (loan.status === 'completed')
        reset(
          {
            clearingNumber: loan?.data?.AccountClearingNumber ?? undefined,
            accountNumber: loan?.data?.AccountNumber ?? undefined,
          },
          {
            keepIsSubmitted: true,
            keepSubmitCount: true,
            keepDirty: true,
          }
        );
    }, [loan, reset]);

    const onFormSubmit = (data: AccountNumber) => {
      setShowMessage(false);
      setInput(data);
      onSubmit?.(data);
      setHasSubmittedOnce(true);
    };

    useImperativeHandle(ref, () => ({
      handleSubmit: () => {
        handleSubmit(onFormSubmit)();
      },
    }));

    const getInputState = (inputName: keyof AccountNumber) =>
      errors[inputName] ? 'error' : 'neutral';
    // FIXME visual glitch with TextInput and adding empty validation message with min height
    // errors[inputName] ? 'error' : touchedFields[inputName] ? 'success' : 'neutral';

    const isLoading = useMemo(() => loan.status === 'loading', [loan.status]);

    const status: 'success' | 'error' | null = useMemo(() => {
      if (!isLoading && isSubmitted) {
        return loan.status === 'completed' && !loan.error ? 'success' : 'error';
      } else {
        return null;
      }
    }, [loan.status, loan.error, isLoading, isSubmitted]);

    useEffect(() => {
      if (isSubmitted && loan.status === 'completed' && !!status) {
        setShowMessage(true);
        if (status === 'error') {
          // reset();
          setHasSubmittedOnce(false);
        }
      }
    }, [loan.status, status]);

    useEffect(() => {
      if (isDirty) {
        setShowMessage(false);
      }
    }, [isDirty]);

    return (
      <Stack gap={'2xs'}>
        {title && typeof title === 'string' ? (
          <Heading size="s">{title}</Heading>
        ) : (
          title || <Heading size="s">{t('form.title')}</Heading>
        )}
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Grid columns={{ s: 2, m: 2, l: 3 }} className={styles.grid}>
            <FormControl
              state={getInputState('clearingNumber')}
              className={styles.formControl}
            >
              <FormControl.Label>
                {clearingNumberLabel || t('form.clearingNumber')}
              </FormControl.Label>
              <TextInput
                {...register('clearingNumber')}
                {...constraints.clearingNumber}
                _ref={register('clearingNumber').ref}
                state={getInputState('clearingNumber')}
                disabled={hasSubmittedOnce}
              />
              <FormControl.Validation>
                {errors.clearingNumber && (
                  <>
                    {validation?.clearingNumber ||
                      t('form.validationMessages.clearingNumberInvalid')}
                    {/* TODO validation specific error translations */}
                    {/*<span>{errors.clearingNumber.message}</span>*/}
                  </>
                )}
              </FormControl.Validation>
            </FormControl>
            <FormControl
              state={getInputState('accountNumber')}
              className={styles.formControl}
            >
              <FormControl.Label>
                {accountNumberLabel || t('form.accountNumber')}
              </FormControl.Label>
              <TextInput
                {...register('accountNumber')}
                {...constraints.accountNumber}
                _ref={register('accountNumber').ref}
                state={getInputState('accountNumber')}
                disabled={hasSubmittedOnce}
              />
              <FormControl.Validation>
                {errors.accountNumber && (
                  <>
                    {validation?.accountNumber ||
                      t('form.validationMessages.accountNumberInvalid')}
                    {/* TODO validation specific error translations */}
                    {/*<span>{errors.accountNumber.message}</span>*/}
                  </>
                )}
              </FormControl.Validation>
            </FormControl>
            {!ref && (
              <Stack gap={'2xs'} className={styles.action}>
                {showMessage && !!status && (
                  <Message size={'s'} variant={status} hasIcon={false}>
                    {t(`form.status.${status}`)}
                  </Message>
                )}
                <Button
                  type="submit"
                  loading={isLoading}
                  disabled={!isDirty || isLoading || hasSubmittedOnce}
                >
                  {t('form.action')}
                </Button>
              </Stack>
            )}
          </Grid>
        </form>
      </Stack>
    );
  }
);
