import { clsx } from 'clsx';
import { ReactElement } from 'react';
import { Icon, IconName } from '../../../Icon';
import { InputStateVariant } from '../../InputProps.types';

import styles from './inputStateIndicator.module.scss';

const iconStateMap: Partial<Record<NonNullable<InputStateVariant>, IconName>> =
  {
    error: 'exclamation-circle-solid',
    success: 'check-circle-solid',
  };

type InputStateIndicatorProps = {
  className?: string;
  state?: InputStateVariant;
};

/**
 * InputStateIndicator component
 */
export const InputStateIndicator = ({
  className,
  state = 'neutral',
  ...props
}: InputStateIndicatorProps): ReactElement | null => {
  return ['error', 'success'].includes(state) ? (
    <span
      className={clsx(styles.inputStateIndicator, className)}
      data-state={state}
      {...props}
    >
      <Icon name={iconStateMap[state] as IconName} size={'s'} />
    </span>
  ) : null;
};
