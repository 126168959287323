import { insurance_payment_api } from '@axo/shared/data-access/api';
import { useAPI } from '@axo/shared/data-access/provider';
import { useQuery } from 'react-query';
import {
  insurancePaymentKeys,
  parseInsurancePaymentKey,
} from '../cache-keys/insurancePaymentKeys';
import { queryFunction } from '../query-function';

export function useInsuranceBankIdMandate(insurancePaymentID?: string) {
  const api = useAPI();
  const cacheKey = insurancePaymentKeys.root({
    insurancePaymentID: insurancePaymentID ?? '',
  });

  return useQuery(
    cacheKey,
    queryFunction(
      insurance_payment_api.getBankIdMandate(api),
      ({ queryKey }) => parseInsurancePaymentKey(queryKey).insurancePaymentID
    ),
    {
      enabled: !!insurancePaymentID,
      refetchInterval: (data) =>
        !data?.AssentlyJWT && !data?.AgreementDigest
          ? 1_000
          : data?.AssentlyJWT?.length === 0
          ? 1_000
          : false,
      keepPreviousData: true,
    }
  );
}
