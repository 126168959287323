import { useEffect } from 'react';
import { useDataStore } from './store/useDataStore';
import { useViewStateMachine } from './store/useViewStateMachine';

export type ProductInsuranceState = {
  onComplete?: () => void;
  onCancel?: (reason: 'opt-out' | 'not-eligible') => void;
  onError?: () => void;
};

type UseProductInsuranceProps = ProductInsuranceState;

export const useProductInsurance = ({
  onComplete,
  onCancel,
}: UseProductInsuranceProps) => {
  const isEligible = useDataStore((state) => state.application.isEligible);

  useEffect(() => {
    if (!isEligible) onCancel?.('not-eligible');
  }, [isEligible]);

  const isEnabled = useViewStateMachine((state) => state.isEnabled);

  useEffect(() => {
    if (!isEnabled) onCancel?.('opt-out');
  }, [isEnabled]);

  const isLastStep = useViewStateMachine((state) => state.isLastStep);
  useEffect(() => {
    if (isLastStep) onComplete?.();
  }, [isLastStep]);
};
