import { Icon } from '@axo/ui-core/components/Icon';
import { Stack } from '@axo/ui-core/components/layout/item';
import { Text } from '@axo/ui-core/components/typography';
import { useTranslation } from 'react-i18next';
import { namespace } from '../../../config/i18n.config';
import styles from './confirmationScreen.module.scss';

export const ConfirmationScreen = () => {
  const { t } = useTranslation(namespace, { keyPrefix: 'steps.confirmation' });

  return (
    <Stack className={styles.confirmationScreen}>
      <Text size={'m'} className={styles.title}>
        <Icon name={'check-fat-solid'} size={'s'} />
        <b>{t('title')}</b>
      </Text>
      <Text size={'s'} className={styles.description}>
        {t('description')}
      </Text>
    </Stack>
  );
};
