import { useMutation, useQueryClient } from 'react-query';
import { loan_application } from '@axo/shared/data-access/types';
import { applicationKeys } from '../cache-keys/applicationKeys';
import { useAPI } from '@axo/shared/data-access/provider';
import { loan_application_api } from '@axo/shared/data-access/api';

interface IUseUpdateApplicationDetails {
  applicationID: string;
  patch: Partial<loan_application.LoanApplication>;
}

export function useUpdateApplicationDetails() {
  const api = useAPI();
  const client = useQueryClient();

  return useMutation(
    ({ applicationID, patch }: IUseUpdateApplicationDetails) =>
      loan_application_api.patchLoanApplication(api)(applicationID, patch),
    {
      onSuccess: (_, params) => {
        client.invalidateQueries(
          applicationKeys.root({
            applicationID: params.applicationID,
          })
        );
      },
    }
  );
}
