import { Button } from '@axo/ui-core/components/Button';
import { Stack } from '@axo/ui-core/components/layout/item';
import { Text } from '@axo/ui-core/components/typography';
import { useTranslation } from 'react-i18next';
import { namespace } from '../../config/i18n.config';
import { useViewStateMachine } from '../../store/useViewStateMachine';
import type { ViewStep } from '../../store/view-state-machine.types';

import styles from './footer.module.scss';

export const Footer = () => {
  const { t } = useTranslation(namespace, { keyPrefix: 'navigation' });
  const {
    currentStep,
    steps,
    isFirstStep,
    isLastStep,
    goToNextStep,
    isStepValid,
    isPaymentMandateStep,
    toggleEnabled,
    isEnabled,
  } = useViewStateMachine();
  const paymentProviderError = useViewStateMachine(
    (state) => state.formData.paymentProviderError
  );

  return (
    <Stack
      className={styles.footer}
      data-is-first-step={isFirstStep || undefined}
      data-is-last-step={isLastStep || undefined}
      data-is-payment-mandate-step={isPaymentMandateStep || undefined}
      data-retry-payment-mandate={paymentProviderError || undefined}
      data-is-ui-disabled={!isEnabled || undefined}
    >
      <Text className={styles.intro}>{t('intro')}</Text>
      <Stack className={styles.actions}>
        <Button
          variant="primary"
          size="small"
          state="success"
          onClick={goToNextStep}
          disabled={!isStepValid && !paymentProviderError}
          className={styles.btnNext}
        >
          {t(`next.${steps[currentStep] as ViewStep}`)}
        </Button>

        <Button
          variant="tertiary"
          size="small"
          onClick={toggleEnabled}
          className={styles.btnToggleEnabled}
        >
          {isEnabled ? t('skip') : t('enable')}
        </Button>
      </Stack>
      <Text size={'s'} className={styles.steps}>
        <b>{currentStep}</b> {` / ${steps.length - 2}`}
      </Text>
    </Stack>
  );
};
