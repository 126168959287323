import { Icon } from '@axo/ui-core/components/Icon';
import { Stack } from '@axo/ui-core/components/layout/item';
import { Text } from '@axo/ui-core/components/typography';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigService } from '../../../config/ConfigService';
import { namespace } from '../../../config/i18n.config';
import { useViewStateMachine } from '../../../store/useViewStateMachine';
import styles from './introScreen.module.scss';

export const IntroScreen = () => {
  const { t } = useTranslation(namespace, { keyPrefix: 'steps.intro' });
  const insuredAmount = useViewStateMachine(
    (state) => state.formData.insuredAmount
  );

  const {
    currency,
    i18n: { defaultLocale },
  } = ConfigService.config;

  const Currency = new Intl.NumberFormat(defaultLocale, {
    style: 'currency',
    currency: currency,
    maximumFractionDigits: 0,
  });

  const usps = useMemo(
    () =>
      t('usps', {
        returnObjects: true,
        insuredAmount: Currency.format(insuredAmount),
      }) as string[],
    [t, insuredAmount]
  );

  return (
    <Stack className={styles.introScreen} as="ul">
      {usps.map((item, index) => (
        <li className={styles.item} key={index}>
          <Icon name={'badge-check-solid'} size={'s'} />
          <Text
            size={'m'}
            className={styles.label}
            dangerouslySetInnerHTML={{ __html: item }}
          />
        </li>
      ))}
    </Stack>
  );
};
