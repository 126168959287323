import type { ClientEmbedUrl } from './assently-service.types';

export const addClientToDom = ({
  clientEmbedUrl = 'https://coreid-test.assently.com/embed/coreid.js',
  doc = document,
  onLoad,
}: {
  clientEmbedUrl?: ClientEmbedUrl;
  doc?: Document;
  onLoad: () => void;
}) => {
  const script = doc.createElement('script');
  script.src = clientEmbedUrl;
  script.async = true;
  script.onload = onLoad;

  return doc.body.appendChild(script);
};
