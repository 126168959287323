import { Button } from '@axo/ui-core/components/Button';
import styles from './ActionBar.module.scss';

type ActionButtonProps = {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  variant?: 'primary' | 'tertiary';
  classes?: string;
};

type ActionBarProps = {
  backButton?: ActionButtonProps;
  nextButton?: ActionButtonProps;
};

export const ActionBar = ({ backButton, nextButton }: ActionBarProps) => {
  return (
    <div className={styles.buttons}>
      {backButton && (
        <Button
          variant={backButton.variant ?? 'tertiary'}
          size="small"
          onClick={backButton.onClick}
          disabled={backButton.disabled}
          className={backButton.classes}
        >
          {backButton.text}
        </Button>
      )}
      {nextButton && (
        <Button
          variant={nextButton.variant ?? 'primary'}
          size="small"
          onClick={nextButton.onClick}
          disabled={nextButton.disabled}
          className={nextButton.classes}
        >
          {nextButton.text}
        </Button>
      )}
    </div>
  );
};
