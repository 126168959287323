import { Stack } from '@axo/ui-core/components/layout/item';
import { Message } from '@axo/ui-core/components/Message';
import { Text } from '@axo/ui-core/components/typography';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { namespace } from '../../../config/i18n.config';
import { useViewStateMachine } from '../../../store/useViewStateMachine';
import { PaymentProvider } from '../../PaymentProvider/PaymentProvider';
import styles from './paymentMandateScreen.module.scss';

export const PaymentMandateScreen = () => {
  const { t } = useTranslation(namespace, {
    keyPrefix: 'steps.paymentMandate',
  });

  const paymentProviderError = useViewStateMachine(
    (state) => state.formData.paymentProviderError
  );

  const messageType: 'warn' | 'error' | null = useMemo(
    () => (paymentProviderError === 'canceled' ? 'warn' : paymentProviderError),
    [paymentProviderError]
  );

  return (
    <Stack className={styles.paymentMandateScreen}>
      {messageType ? (
        <Message variant={messageType} hasIcon>
          {t(`message.${messageType}`)}
        </Message>
      ) : null}
      <Text size="m">
        <b>{t('title')}</b>
      </Text>
      <PaymentProvider />
    </Stack>
  );
};
