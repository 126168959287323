import {
  useInsuranceBankIdMandate,
  useInsuranceBsMandate,
  useInsuranceCardMandate,
  useInsuranceKidMandate,
} from '@axo/shared/data-access/hooks';
import type { PaymentMandateType } from '@axo/shared/data-access/types/insurance_payment';

/***/

type UseInsurancePaymentMandateApiImpl =
  | typeof useInsuranceBankIdMandate
  | typeof useInsuranceBsMandate // TODO verify
  | typeof useInsuranceCardMandate
  | typeof useInsuranceKidMandate;

/**
 * Factory for different PaymentMandateType implementations
 *
 * used by `useCreateInsurancePaymentMandate` to handle different `paymentMandateType`s
 */

export const useInsurancePaymentMandateApiImpl = (
  paymentMandateType: PaymentMandateType = 'card'
) => {
  const implMap: Record<PaymentMandateType, UseInsurancePaymentMandateApiImpl> =
    {
      bankid: useInsuranceBankIdMandate,
      bs: useInsuranceBsMandate,
      card: useInsuranceCardMandate,
      kid: useInsuranceKidMandate,
    };

  return implMap[paymentMandateType];
};

/**
 * Helper
 *
 * @note keep in sync with the `refetchInterval` criteria in the hook.
 * Each `paymentMandateType` requires certain properties to be available
 * before initiating the payment mandate flow w/ external providers.
 */

const isBankIdDataComplete = (data: any): boolean => {
  return !!data?.AssentlyJWT && !!data?.AgreementDigest;
};

const isBsDataComplete = (data: any): boolean => {
  return !!data?.PaymentURL; // TODO verify
};

const isCardDataComplete = (data: any): boolean => {
  return !!data?.EmbedURL;
};

const isKidDataComplete = (data: any): boolean => {
  return !!data?.PaymentURL;
};

const dataValidators: Record<PaymentMandateType, (data: any) => boolean> = {
  bankid: isBankIdDataComplete,
  bs: isBsDataComplete,
  card: isCardDataComplete,
  kid: isKidDataComplete,
};

export const isInsurancePaymentMandateDataComplete = (
  paymentMandateType: PaymentMandateType,
  data: any
): boolean => {
  const validator = dataValidators[paymentMandateType];

  if (!validator) {
    throw new Error(`Unsupported payment mandate type: ${paymentMandateType}`);
  }

  return validator(data);
};
