import { Card } from '@axo/ui-core/components/Card';
import { Skeleton } from '@axo/ui-core/components/Skeleton';
import { AnimatePresence, motion } from 'motion/react';
import type { ReactNode } from 'react';
import { ConfigService } from '../../config/ConfigService';
import { useDataStore } from '../../store/useDataStore';
import { useViewStateMachine } from '../../store/useViewStateMachine';
import { Footer } from '../Footer/Footer';
import { AmountSelector } from '../Header/AmountSelector';
import { Header } from '../Header/Header';

import {
  enabledStateAnimations,
  heightAnimation,
} from './WidgetLayout.animation';
import styles from './widgetLayout.module.scss';
import { WidgetLayoutSteps } from './WidgetLayoutSteps';

type WidgetLayoutProps = {
  children?: ReactNode;
};

export const WidgetLayout = ({ children }: WidgetLayoutProps) => {
  const { isEnabled, currentStep, isLoading } = useViewStateMachine();
  const { isEligible } = useDataStore((state) => state.application);

  if (!isEligible && !isLoading) return null;
  if (isLoading) return <Skeleton className={styles.skeleton} />;

  return (
    <Card
      className={styles.widgetLayout}
      data-is-ui-disabled={!isEnabled || undefined}
    >
      <Card.Header className={styles.header}>
        <motion.div
          className={styles.motion}
          initial="enabled"
          animate={isEnabled ? 'enabled' : 'disabled'}
          variants={enabledStateAnimations}
        >
          <Header country={ConfigService.config.country}>
            <AmountSelector isInteractive={currentStep < 2} />
          </Header>
        </motion.div>
      </Card.Header>
      <Card.Content className={styles.content}>
        <AnimatePresence initial={false}>
          {isEnabled && (
            <motion.div
              className={styles.motion}
              variants={heightAnimation}
              initial={'initial'}
              animate={'visible'}
              exit={'hidden'}
            >
              <WidgetLayoutSteps />
            </motion.div>
          )}
        </AnimatePresence>
      </Card.Content>
      <Card.Footer className={styles.footer}>
        <Footer />
      </Card.Footer>
    </Card>
  );
};
