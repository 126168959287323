import { clsx } from 'clsx';
import { ReactElement, ReactNode } from 'react';
import { InputStateVariant } from '../../input/InputProps.types';
import { FormControlCaption } from './Caption/FormControlCaption';

import styles from './formControl.module.scss';
import { FormControlLabel } from './Label/FormControlLabel';
import { FormControlValidation } from './Validation/FormControlValidation';

type FormControlProps = {
  children?: ReactNode;
  className?: string;
  state?: InputStateVariant;
} & HTMLDataAttributes;

/**
 * FormControl component
 */
const FormControlRoot = ({
  children,
  className,
  state = 'neutral',
  ...props
}: FormControlProps): ReactElement => {
  return (
    <div
      className={clsx(styles.formControl, className)}
      data-state={state}
      data-form-control-element="form-control"
      {...props}
    >
      {children}
    </div>
  );
};

export const FormControl = Object.assign(FormControlRoot, {
  Caption: FormControlCaption,
  Label: FormControlLabel,
  Validation: FormControlValidation,
});
