import type { ApplicationData, RequestState } from './data-store.types';
import { useDataStore } from './useDataStore';

export const getUpdateRequestState = (key: keyof ApplicationData) => {
  // const setRequestState = useDataStore((state) => state.setRequestState);
  const setRequestState = useDataStore.getState().setRequestState;

  const updateRequestState = (partialState: Partial<RequestState>) => {
    setRequestState(key, partialState);
  };

  return { updateRequestState };
};
