import { FormControl } from '@axo/ui-core/components/form/FormControl';
import type { InputStateVariant } from '@axo/ui-core/components/input';
import { TextInput } from '@axo/ui-core/components/input/TextInput';
import { Stack } from '@axo/ui-core/components/layout/item';
import { Text } from '@axo/ui-core/components/typography';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { namespace } from '../../../config/i18n.config';
import type {
  AccountNumberLabels,
  TextInput as TextInputLabels,
} from '../../../locales/content.types';
import { useDataStore } from '../../../store/useDataStore';
import { useViewStateMachine } from '../../../store/useViewStateMachine';
import styles from './accountNumberForm.module.scss';
import {
  AccountNumberSeConstraints as constraints,
  AccountNumberSeSchema as schema,
} from './schema/AccountNumber.se.schema';
import type { SignTerm } from './SignInsuranceTerm.types';

/**
 * @note AccountNumber is SE x BankId specific
 */

export const AccountNumberForm = () => {
  const { t } = useTranslation(namespace, {
    keyPrefix: 'steps.sign.accountNumber',
  });
  const { updateFormData } = useViewStateMachine();
  const { hasBankAccount } = useViewStateMachine((state) => state.formData);
  const { setInput } = useDataStore();

  const labels = t('form', {
    returnObjects: true,
  }) as AccountNumberLabels;

  const keys = Object.keys(labels);
  const defaultValues = keys.reduce((acc, key) => {
    acc[key] = undefined;
    return acc;
  }, {} as Record<string, undefined>);

  const {
    register,
    getFieldState: _getFieldState,
    formState: { isValid, isValidating, errors },
    getValues,
  } = useForm<SignTerm>({
    resolver: zodResolver(schema),
    mode: 'onChange',
    defaultValues,
  });

  const getField = useCallback(
    (name: string) => {
      const state = _getFieldState(name);

      return {
        state: (!state.isDirty
          ? 'neutral'
          : !state.invalid
          ? 'success'
          : !state.isTouched
          ? 'neutral'
          : 'error') as InputStateVariant,
        error: state.error,
      };
    },
    [_getFieldState, getValues]
  );

  useEffect(() => {
    if (isValid !== hasBankAccount) {
      if (isValid) {
        const values = keys.reduce((acc, key) => {
          acc[key] = getValues(key) as unknown as string;
          return acc;
        }, {} as Record<string, string>);

        setInput(values);
      }
      updateFormData({ hasBankAccount: isValid });
    }
  }, [isValid, hasBankAccount, getValues]);

  const fieldComponents = useMemo(
    () =>
      Object.entries(labels).map(([key, input]: [string, TextInputLabels]) => {
        const { ref, ...inputProps } = register(key);
        const { state, error } = getField(key);

        return (
          <FormControl key={key} className={styles.inputControl} state={state}>
            <FormControl.Label htmlFor={key} className={styles.label}>
              {input.label}
            </FormControl.Label>
            <TextInput
              {...inputProps}
              _ref={ref}
              {...constraints[key as keyof typeof constraints]}
              state={state}
              className={styles.input}
            />
            <FormControl.Caption />
            <FormControl.Validation>
              {error ? input.validation.invalid : null}
            </FormControl.Validation>
          </FormControl>
        );
      }),
    [labels, register, getField, errors]
  );

  return (
    <Stack className={styles.accountNumberForm}>
      <Text size="m" className={styles.title}>
        <b>{t('title')}</b>
      </Text>
      <Text size="m" className={styles.description}>
        {t('description')}
      </Text>
      <form className={styles.form}>{fieldComponents}</form>
      <Text size="s" className={styles.note}>
        {t('note')}
      </Text>
    </Stack>
  );
};
