import type { ProductConfig } from './product-config.types';

export const productConfig: ProductConfig = {
  name: 'insurance.fi',
  i18n: {
    locales: ['en-GB', 'fi-FI'],
    defaultLocale: 'fi-FI',
  },
  country: 'FI',
  currency: 'EUR',
  paymentMandateType: 'card',
  policy: {
    price: [
      { payout: 200, premium: 17.49 },
      { payout: 300, premium: 22.49 },
      { payout: 400, premium: 27.49 },
      { payout: 500, premium: 35.49 },
      { payout: 600, premium: 40.99 },
      { payout: 700, premium: 46.49 },
      { payout: 800, premium: 51.99 },
      { payout: 900, premium: 57.99 },
      { payout: 1000, premium: 63.49 },
    ],
  },
};
