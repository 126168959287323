import { Button } from '@axo/deprecated/util/ui-components';
import { QuoteItemContainer } from '@axo/mypage/ui/atom';
import { MyPageFlags } from '@axo/mypage/util';
import { useTranslation } from '@axo/mypage/util/translation';
import {
  EventCode,
  KnownResourceType,
  useEventLogger,
} from '@axo/shared/data-access/event-log';
import { loan_quote_presentation } from '@axo/shared/data-access/types';
import { useFlags, useTypedFlags } from '@axo/shared/services/feature-flags';
import { Icons } from '@axo/shared/ui/atoms';
import { LenderLogo } from '@axo/shared/ui/atoms/LenderLogo';
import { classNames } from '@axo/shared/util/dom';
import { useMemo, useState } from 'react';
import { HighCostWarning } from './HighCostWarning';
import styles from './LoanQuoteListItem.module.scss';
import { QuoteDetail } from './QuoteDetail';
import { QuoteDetailSplit } from './QuoteDetailSplit';
import { QuoteInfoItem } from './QuoteInfoItem';
import { useOfferTypeExplanation } from './useOfferTypeExplanation';

export enum QuoteState {
  Recommended,
  Selected,
  PreviouslySelected,
  Disbursed,
  None,
  Cancelled,
  Rejected,
}

interface ILoanQuoteListItem {
  quote: loan_quote_presentation.EnrichedLoanQuote;
  action?: () => void;
  state: QuoteState;
  isLoadingAction?: boolean;
  marketCountry: string;
  disableUserAccept?: boolean;
  offersUnlocked?: boolean;
}

export function LoanQuoteListItem({
  quote,
  action,
  state,
  isLoadingAction,
  marketCountry,
  disableUserAccept,
  offersUnlocked,
}: ILoanQuoteListItem) {
  const [detailsOpen, setDetailsOpen] = useState(false);
  const { t } = useTranslation();
  const log = useEventLogger(
    {
      Type: KnownResourceType.LoanQuote,
      ID: quote.ID,
    },
    {
      Type: KnownResourceType.LoanRequest,
      ID: quote.LoanRequestID,
    }
  );
  const {
    flag_show_loan_type_explanation,
    flag_show_split_quote_details,
    flag_show_lender_info,
    flag_show_inline_offer_explanation,
  } = useTypedFlags(MyPageFlags);
  const {
    'show-high-cost-warning-banner': flag_show_high_cost_warning_banner,
  } = useFlags(MyPageFlags);

  const onToggleDetails = () => {
    log(EventCode.ToggleDetails, { open: (!detailsOpen).toString() });
    setDetailsOpen((prevState) => !prevState);
  };

  const lender = quote.Lender;

  const isSelected = state === QuoteState.Selected;
  const isPreviouslySelected = state === QuoteState.PreviouslySelected;
  const isDisbursed = state === QuoteState.Disbursed;
  const isCancelled = state === QuoteState.Cancelled;
  const isRejected = state === QuoteState.Rejected;

  const showPrimaryBankInfo = lender.Info[0] && flag_show_lender_info;
  const showSecondaryBankInfo = lender.Info[1] && flag_show_lender_info;
  const showLoanTypeExplanation =
    quote?.LoanType && flag_show_loan_type_explanation;
  const showDetails =
    showPrimaryBankInfo || showSecondaryBankInfo || showLoanTypeExplanation;

  const getText = (state: QuoteState) => {
    switch (state) {
      case QuoteState.Recommended:
        return `${t('Recommended')}`;
      case QuoteState.Selected:
        return `${t('Selected')}`;
      case QuoteState.PreviouslySelected:
        return `${t('Previously selected')}`;
      case QuoteState.Disbursed:
        return `${t('Disbursed')}`;
      case QuoteState.Cancelled:
        return `${t('Cancelled')}`;
      case QuoteState.Rejected:
        return `${t('Cancelled')}`;
      default:
        return '';
    }
  };

  const getSelectButtonText = () => {
    if (isSelected || isPreviouslySelected) return t('Continue');
    if (!offersUnlocked) return t('Waiting for more offers');
    return t('Choose');
  };

  const offerTypeExplanation = useOfferTypeExplanation({ quote });

  const brokerConfiguration = lender.BrokerConfiguration?.find(
    (item) => item.Broker === quote?.Broker
  );
  const showHighCostWarning =
    brokerConfiguration?.FrontendConfiguration?.UnsecuredLoanHighCostCredit &&
    (quote?.EffectiveRate ?? 0) > 32.75;

  const isDetailButtonVisible = useMemo(
    () =>
      offersUnlocked &&
      (showDetails || offerTypeExplanation || showHighCostWarning),
    [showDetails, offerTypeExplanation, showHighCostWarning, offersUnlocked]
  );

  return (
    <>
      <QuoteItemContainer state={state} getText={getText}>
        <div className={styles.detailsContainer}>
          <div className={classNames(styles.lenderLogoContainer)}>
            {lender && (
              <LenderLogo
                lender={lender}
                className={styles.lenderLogo}
                marketCountry={marketCountry}
              />
            )}
          </div>
          {flag_show_split_quote_details ? (
            <QuoteDetailSplit quote={quote} showSecondRow={detailsOpen} />
          ) : (
            <QuoteDetail quote={quote} />
          )}
          <div className={styles.buttonContainer}>
            {isDetailButtonVisible && (
              <Button
                variant="secondary"
                onClick={onToggleDetails}
                endIcon={
                  <Icons.Caret
                    size="sm"
                    color={{ primary: '--dark-700' }}
                    classes={{ root: detailsOpen ? styles.caretFlipped : '' }}
                  />
                }
                fontVariant={'secondary'}
                classes={{ root: styles.button }}
              >
                {t('Details')}
              </Button>
            )}
            <Button
              variant="primary"
              onClick={action}
              loading={isLoadingAction}
              classes={{
                root: classNames(
                  styles.button,
                  styles.actionButton,
                  isPreviouslySelected && styles.black
                ),
              }}
              disabled={
                isDisbursed ||
                isCancelled ||
                isLoadingAction ||
                disableUserAccept ||
                !offersUnlocked
              }
            >
              {getSelectButtonText()}
            </Button>
          </div>
        </div>
        {flag_show_high_cost_warning_banner && showHighCostWarning && (
          <div className={styles.highCostWarning}>
            <div className={styles.divider} />
            <HighCostWarning />
          </div>
        )}
        {flag_show_inline_offer_explanation && offerTypeExplanation && (
          <div className={styles.refinanceExplainer}>
            <div className={styles.divider} />
            <div className={styles.refinanceExplainerContent}>
              <Icons.CircleInfoOutline
                color={{ primary: '--info-500' }}
                size="sm"
              />
              <p>{offerTypeExplanation}</p>
            </div>
          </div>
        )}
      </QuoteItemContainer>
      {(isCancelled || (detailsOpen && showDetails)) && (
        <div className={styles.detailBannerContainer}>
          {(isCancelled || isRejected) && (
            <div className={styles.detailUsefulInfo}>
              <QuoteInfoItem icon={'outline'}>
                {isRejected && t('This offer is no longer active')}
                {isCancelled &&
                  t(
                    'Closed offer. To reactivate your offer please contact our customer service'
                  )}
              </QuoteInfoItem>
            </div>
          )}
          {detailsOpen && showDetails && (
            <>
              <span className={styles.title}>{t('Useful information')}</span>
              <div className={styles.detailUsefulInfo}>
                {showLoanTypeExplanation && (
                  <QuoteInfoItem>{offerTypeExplanation}</QuoteInfoItem>
                )}
                {showPrimaryBankInfo && (
                  <QuoteInfoItem>{lender.Info[0].InfoText}</QuoteInfoItem>
                )}
                {showSecondaryBankInfo && (
                  <QuoteInfoItem>{lender.Info[1].InfoText}</QuoteInfoItem>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}
