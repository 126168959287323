import * as RadixAccordion from '@radix-ui/react-accordion';
import { ForwardedRef, forwardRef, ReactElement, ReactNode } from 'react';
import { Icon } from '../../Icon';
import { AccordionItemStyleVariant } from '../Accordion.types';
import styles from './AccordionItem.module.scss';
import clsx from 'clsx';

type IAccordionItemHeaderProps = {
  children: ReactNode;
  styleVariant?: AccordionItemStyleVariant;
  className?: string;
};

export const AccordionItemHeader = forwardRef(
  (
    {
      children,
      className,
      styleVariant = 'default',
      ...props
    }: IAccordionItemHeaderProps,
    forwardedRef: ForwardedRef<HTMLButtonElement>
  ): ReactElement => (
    <RadixAccordion.Header
      className={styles.accordionItemHeader}
      data-element={'header'}
    >
      <RadixAccordion.Trigger
        ref={forwardedRef}
        className={clsx(styles.trigger, className)}
        data-element={'trigger'}
        {...props}
      >
        <div className={styles.content}>{children}</div>
        <div className={styles.icon}>
          {styleVariant === 'default' && <Icon name="chevron-down" />}
          {styleVariant === 'faq' && (
            <>
              <Icon name="plus-circle" />
              <Icon name="minus-circle" />
            </>
          )}
        </div>
      </RadixAccordion.Trigger>
    </RadixAccordion.Header>
  )
);
