import { IAcceptQuote } from '@axo/mypage/data-access';
import { AcceptModal } from '@axo/mypage/ui/atom';
import { breadcrumbs } from '@axo/mypage/util';
import { useTranslation } from '@axo/mypage/util/translation';
import {
  EventCode,
  KnownResourceType,
  useEventLogger,
  useEventResource,
} from '@axo/shared/data-access/event-log';
import { loan_quote_presentation } from '@axo/shared/data-access/types';
import { MarketCountryCode } from '@axo/shared/types';
import { LenderLogo } from '@axo/shared/ui/atoms/LenderLogo';
import { Button } from '@axo/ui-core/components/Button';
import { Icon } from '@axo/ui-core/components/Icon';
import { Heading } from '@axo/ui-core/components/typography';
import { AccountNumberForm } from '@axo/ui-feature/components/AccountNumber';
import { AccountNumber } from '@axo/ui-feature/components/AccountNumber/AccountNumberForm.types';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import styles from './AcceptOfferModal.module.scss';

const acceptModalClasses = {
  root: styles.modalRoot,
  content: styles.modalContent,
  actionBar: { root: styles.actionContainer, buttons: styles.buttons },
};

export interface StoredOffer {
  offer: loan_quote_presentation.PresentedLoanQuote;
  acceptableIndex: number;
}

interface IAcceptOfferModal {
  offer: StoredOffer;
  closeModal: () => void;
  accept: IAcceptQuote;
  marketCountry: string;
  doAccept?: (data?: AccountNumber) => Promise<void>;
  accountNumberRequired?: boolean;
}

export function AcceptOfferModal({
  offer,
  closeModal,
  accept,
  marketCountry,
  doAccept,
  accountNumberRequired,
}: IAcceptOfferModal) {
  const log = useEventLogger(...breadcrumbs(offer.offer));
  const { t } = useTranslation();

  useEventResource(KnownResourceType.Lender, offer.offer.LenderID);
  useEventResource(KnownResourceType.LoanRequest, offer.offer.LoanRequestID);
  useEventResource(KnownResourceType.LoanQuote, offer.offer.ID);

  const formRef = useRef<{ handleSubmit: () => void } | null>(null);

  const handleAccept = useCallback(
    async (data?: AccountNumber) => {
      if (doAccept) {
        await doAccept(data);
        closeModal();
      }
    },
    [doAccept, closeModal]
  );

  const onAccept = useCallback(() => {
    if (formRef.current) {
      formRef.current.handleSubmit();
      return;
    }

    handleAccept();
  }, [formRef, handleAccept]);

  const onFormSubmit = (data: AccountNumber) => {
    handleAccept(data);
  };

  useEffect(() => {
    log(EventCode.OpenAcceptOfferModal);
  }, [log]);

  const onClose = useCallback(() => {
    log(EventCode.CloseAcceptOfferModal);
    closeModal();
  }, [closeModal, log]);

  // const isSubtitleVisible = marketCountry !== MarketCountryCode.Norway;
  // const isButtonHeaderVisible = marketCountry !== MarketCountryCode.Norway;
  // we accept on select on the overview, does not make sense to ask again
  const isTitleVisible = false;
  const isSubtitleVisible = true;
  const isButtonHeaderVisible = false;

  const acceptModalActionBar = useMemo(
    () => ({
      title: isButtonHeaderVisible ? (
        <>
          {t('Do you want to continue with')}{' '}
          <span className={styles.bold}>{offer.offer.Lender?.Name}</span>?
        </>
      ) : null,
      buttons: (
        <>
          <Button variant={'tertiary'} size={'small'} onClick={onClose}>
            <Icon name="arrow-left" size="s" />
          </Button>
          <Button
            size={'small'}
            onClick={onAccept}
            disabled={accept.isLoading}
            loading={accept.isLoading}
          >
            {accountNumberRequired
              ? t('Confirm and proceed to bank page')
              : t('Continue to bank')}
          </Button>
        </>
      ),
    }),
    [
      offer.offer.Lender?.Name,
      t,
      accept.isLoading,
      accountNumberRequired,
      onClose,
      onAccept,
      isButtonHeaderVisible,
    ]
  );

  const accountNumberFormValidation = useMemo(
    () => ({
      clearingNumber: t('Please enter a valid clearing number'),
      accountNumber: t('Please enter a valid account number'),
    }),
    [t]
  );

  return (
    <AcceptModal
      open={!!offer}
      actionBar={acceptModalActionBar}
      classes={acceptModalClasses}
    >
      {isTitleVisible && (
        <Heading size={'s'}>{t('Confirm your selection')}</Heading>
      )}
      {isSubtitleVisible && (
        <span className={styles.subtitle}>
          {t('You have chosen the offer from')}
        </span>
      )}
      <div className={styles.lenderLogoContainer}>
        <div className={styles.lenderLogo}>
          <LenderLogo
            lender={offer.offer.Lender}
            marketCountry={marketCountry}
          />
        </div>
      </div>

      {accountNumberRequired && (
        // SE market only. No translation need
        <div className={styles.accountNumberForm}>
          <AccountNumberForm
            ref={formRef}
            title={<Heading size="xxs">{'Kontouppgifter'}</Heading>}
            clearingNumberLabel={'Clearingnummer'}
            accountNumberLabel={'Kontonummer'}
            onSubmit={onFormSubmit}
            validation={accountNumberFormValidation}
          />
        </div>
      )}
    </AcceptModal>
  );
}
