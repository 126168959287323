export const productFactSheetUrl =
  import.meta.env.VITE_APP_INSURANCE_APP_URL +
  '/Forsikringsvilkår-Axo_Finans_Utgiftsforsikring_240426.pdf?inline=true';

export const prePurchaseInformationAndTermsAndConditionsUrl =
  import.meta.env.VITE_APP_INSURANCE_APP_URL +
  '/Forhåndsinformasjon_og_fullstendige_vilkår-Axo_Finans.pdf?inline=true';

export const prePurchaseInformationUrl =
  import.meta.env.VITE_APP_INSURANCE_APP_URL +
  '/Forhåndsinformasjon-Axo_Finans_Utgiftsforsikring_2024-03-18.pdf?inline=true';
