import { insurance_payment } from '@axo/shared/data-access/types';
import { createMandate, findMandates, getMandate } from './mandate.utils';

/**
 * kid mandate
 *
 * https://gitlab.com/a2755/services/insurance_payment_mandate/-/blob/main/api/openapi.json
 */

const mandateType: insurance_payment.PaymentMandateType = 'kid';

export const createKidMandate =
  createMandate<insurance_payment.CreateKidPaymentMandateRequest>(mandateType);

export const findKidMandates = findMandates(mandateType);

export const getKidMandate = getMandate(mandateType);
