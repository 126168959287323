import { ReactNode } from 'react';

import styles from './Tooltip.module.scss';

export const positionOptions = ['bottom', 'top', 'left', 'right'] as const;

export type TooltipProps = {
  position?: (typeof positionOptions)[number];
  content: ReactNode;
  children: ReactNode;
};

export const Tooltip = ({
  position = 'top',
  content,
  children,
}: TooltipProps) => {
  return (
    <div className={styles.tooltip} data-position={position}>
      <div className={styles.tooltipContent}>{content}</div>
      {children}
    </div>
  );
};
