/**
 * available product variants
 *
 * @note
 * product name must match product config file name
 */

export const products = ['insurance.no', 'insurance.se', 'insurance.fi'];

export type ProductName = (typeof products)[number];
