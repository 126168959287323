import { I18nNsProvider } from '@axo/ui-core/i18n/I18nNsProvider';
import { getLanguageFromLocale } from '@axo/ui-core/i18n/utils/getLanguageFromLocale';
import { useMemo } from 'react';
import { WidgetLayout } from './components/Layout/WidgetLayout';
import { ConfigService } from './config/ConfigService';
import { i18nConfig } from './config/i18n.config';
import { DataAccessProviderFromLibs } from './services/api/DataAccessProviderFromLibs';
import type { InitializeAppProps } from './useInitializeApp';
import { useInitializeApp } from './useInitializeApp';
import type { ProductInsuranceState } from './useProductInsurance';
import { useProductInsurance } from './useProductInsurance';

export type ProductInsuranceProps = {
  view?: 'widget';
} & InitializeAppProps &
  ProductInsuranceState;

/**
 * ProductInsurance
 */

const ProductInsuranceImpl = ({
  view = 'widget',
  onComplete,
  onCancel,
  ...config
}: ProductInsuranceProps) => {
  const { isInitialized } = useInitializeApp(config);

  useProductInsurance({ onComplete, onCancel });

  const localConfig = useMemo(
    () => ({
      ...i18nConfig,
      lng: getLanguageFromLocale(ConfigService.config.i18n.defaultLocale),
    }),
    [ConfigService.config.i18n.defaultLocale]
  );

  return (
    <I18nNsProvider localConfig={localConfig}>
      <WidgetLayout />
    </I18nNsProvider>
  );
};

export const ProductInsurance = (props: ProductInsuranceProps) => {
  /**
   * @note temporary to support legacy DataAccessProviderFromLibs wrapper.
   * It is also initialized in useInitializeApp (no harm as ConfigService is a singleton)
   * once @axo/data-access is available, there will be no need for a wrapper
   * and ProductInsuranceImpl will replace this ProductInsurance component
   */
  ConfigService.initialize(props.product);

  return (
    <DataAccessProviderFromLibs>
      <ProductInsuranceImpl {...props} />
    </DataAccessProviderFromLibs>
  );
};
