import type { BankIdPaymentMandate } from '@axo/shared/data-access/types/insurance_payment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ConfigService } from '../../../../config/ConfigService';
import type { AssentlyServiceResponse } from '../../../../services/assently/assently-service.types';
import { useAssentlyService } from '../../../../services/assently/useAssentlyService';
import { useDataStore } from '../../../../store/useDataStore';
import { useViewStateMachine } from '../../../../store/useViewStateMachine';
import type { PaymentProviderProps } from '../../PaymentProvider.types';
import type { PaymentData } from './bank-id-payment.types';

type UseBankIdPaymentProviderProps = {
  autoStart?: boolean;
  data?: PaymentData;
} & PaymentProviderProps;

// TODO
// expose loading state of view x data (without insurancePaymentMandate we can't start the provider)
// depending on UI flow – use auto start
// auto start replaced by `shouldStartPaymentProcess` in view state machine
export const useBankIdPaymentProvider = ({
  autoStart = false,
  data,
  ...callbacks
}: UseBankIdPaymentProviderProps = {}) => {
  const setInput = useDataStore((state) => state.setInput);
  const insurancePaymentMandate = useDataStore(
    (state) => state.application.insurancePaymentMandate
  );
  const shouldStartPaymentProcess = useViewStateMachine(
    (state) => state.shouldStartPaymentProcess
  );
  const startPaymentProcess = useViewStateMachine(
    (state) => state.startPaymentProcess
  );

  const [isDataReady, setIsDataReady] = useState<boolean>(false);

  const paymentData = useRef<PaymentData | null>(data ?? null);

  useEffect(() => {
    if (insurancePaymentMandate.status === 'completed') {
      const d: BankIdPaymentMandate =
        insurancePaymentMandate.data as BankIdPaymentMandate;

      paymentData.current = {
        token: d.AssentlyJWT,
        sign: {
          title: d.Agreement,
          data: d.AgreementDigest,
        },
      };

      setIsDataReady(true);
    }
  }, [insurancePaymentMandate.status]);

  const onPaymentProviderResponse = useCallback(
    (response: AssentlyServiceResponse) => {
      // console.log('Assently response:', response);

      if (response.type === 'signed') {
        setInput({ paymentMandateProviderResponse: { ...response } });
        callbacks.onSuccess?.();
      }
      if (response.type === 'cancelled') callbacks.onCancel?.();
      if (response.type === 'error') callbacks.onError?.();
    },
    []
  );

  const {
    addClient,
    initialize,
    start,
    isLoaded,
    isInitialized,
    isStarted,
    isSigned,
    isCancelled,
  } = useAssentlyService({
    onResponse: onPaymentProviderResponse,
    isProduction: ConfigService.config.env === 'production',
  });

  const onViewReady = useCallback(
    (doc: Document) => {
      addClient(doc);
    },
    [addClient]
  );

  useEffect(() => {
    if (isLoaded && paymentData.current?.token) {
      initialize({
        token: paymentData.current.token,
        data: { ...paymentData.current.sign },
      });
    }
  }, [
    isLoaded,
    insurancePaymentMandate.status,
    paymentData.current?.token,
    initialize,
  ]);

  const isReady = useMemo(
    () => isLoaded && isInitialized && isDataReady,
    [isLoaded, isInitialized, isDataReady]
  );

  useEffect(() => {
    if (isReady && autoStart) {
      if (!isStarted && !isCancelled && !isSigned) {
        startPaymentProcess(false);
        start();
      }
    }
  }, [autoStart, isReady, isStarted, isSigned]);

  useEffect(() => {
    if (isReady && shouldStartPaymentProcess) {
      if (!isStarted && !isSigned) {
        startPaymentProcess(false);
        start();
      }
    }
  }, [shouldStartPaymentProcess, isReady, isStarted, isSigned]);

  return {
    onViewReady, // iframe ready to inject assently script
    isLoaded, // assently core id client
    isDataReady, // payment mandate data
    isInitialized, // payment mandate data → assently client initialize
    isReady, // all 3 of the above
    onStartPayment: start,
    isStarted, // assently client started, showing assently UI
  };
};
