import * as RadixCheckbox from '@radix-ui/react-checkbox';
import { clsx } from 'clsx';
import { ChangeEvent, ReactElement, Ref, useCallback } from 'react';
import { Icon } from '../../Icon';
import { InputSize, InputStateVariant } from '../InputProps.types';

import styles from './checkbox.module.scss';

type CheckboxProps = {
  name?: string;
  id?: string;
  className?: string;
  state?: InputStateVariant;
  size?: InputSize;

  defaultChecked?: boolean | 'indeterminate';
  checked?: boolean | 'indeterminate';
  disabled?: boolean;
  required?: boolean;
  value?: string | number | readonly string[];

  onCheckedChange?: (checked: boolean) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;

  _ref?: Ref<HTMLButtonElement>;
} & HTMLDataAttributes;

/**
 * Checkbox component
 *
 * @see https://www.radix-ui.com/primitives/docs/components/checkbox
 *
 * @note implement `indeterminate` state (if required) see https://www.radix-ui.com/primitives/docs/components/checkbox#indeterminate
 */
export const Checkbox = ({
  name = 'checkbox',
  id,
  className,
  state = 'neutral',
  size = 'l',
  defaultChecked = false,
  value = 'on',
  onCheckedChange,
  onChange,
  _ref,
  ...props
}: CheckboxProps): ReactElement => {
  const _onCheckedChange = useCallback(
    (checked: boolean) => {
      onCheckedChange?.(checked);

      onChange?.({
        // target: { name, checked, value: checked ? value : undefined },
        // RHF requires value, not value `on` https://www.radix-ui.com/primitives/docs/components/checkbox#root
        target: { name, checked, value: checked },
      } as unknown as ChangeEvent<HTMLInputElement>);
    },
    [name, onChange, onCheckedChange]
  );

  return (
    <RadixCheckbox.Root
      className={clsx(styles.checkbox, className)}
      data-state={state}
      data-size={size}
      name={name}
      id={id ?? name ?? undefined}
      defaultChecked={defaultChecked}
      value={value}
      onCheckedChange={_onCheckedChange}
      {...props}
      ref={_ref ?? undefined}
    >
      <RadixCheckbox.Indicator className={styles.indicator}>
        <Icon name="check" />
      </RadixCheckbox.Indicator>
    </RadixCheckbox.Root>
  );
};
