import * as RadixAccordion from '@radix-ui/react-accordion';
import clsx from 'clsx';
import React, { ReactElement, ReactNode } from 'react';
import { AccordionItemStyleVariant } from '../Accordion.types';
import styles from './AccordionItem.module.scss';

import { AccordionItemContent } from './AccordionItemContent';
import { AccordionItemHeader } from './AccordionItemHeader';

type IAccordionItemProps = {
  header?: ReactNode;
  children?: ReactNode;
  value: string;
  variant?: 'light' | 'dark';
  styleVariant?: AccordionItemStyleVariant;
  className?: string | IClasses;
};

/** @deprecated */
export interface IClasses {
  root?: string;
  header?: string;
  children?: string;
}

/**
 *
 * @todo refactor with proper composition, so header / trigger / content is available that can be styled
 * @see https://www.radix-ui.com/primitives/docs/components/accordion
 */

export const AccordionItem = ({
  header,
  children,
  value,
  variant = 'light',
  styleVariant = 'default',
  className,
}: IAccordionItemProps): ReactElement => {
  const derivedClassName =
    typeof className === 'string' ? className : className?.root;

  return (
    <RadixAccordion.Item
      className={clsx(styles.accordionItem, derivedClassName)}
      value={value}
      data-variant={variant}
      data-style-variant={styleVariant}
    >
      <AccordionItemHeader
        className={clsx(
          'header',
          typeof className === 'object' ? className.header : undefined
        )}
        styleVariant={styleVariant}
      >
        {header}
      </AccordionItemHeader>
      <AccordionItemContent
        className={clsx(
          'content',
          typeof className === 'object' ? className.children : undefined
        )}
      >
        {children}
      </AccordionItemContent>
    </RadixAccordion.Item>
  );
};
