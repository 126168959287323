import { Accordion } from '@axo/ui-core/components/Accordion';
import { Icon } from '@axo/ui-core/components/Icon';
import { Stack } from '@axo/ui-core/components/layout/item';
import { Text } from '@axo/ui-core/components/typography';

import { useTranslation } from 'react-i18next';
import { namespace } from '../../../config/i18n.config';
import type { FAQ } from '../../../locales/content.types';
import styles from './buyScreen.module.scss';
import { SalesTermsForm } from './SalesTermsForm';

export const BuyScreen = () => {
  const { t } = useTranslation(namespace, { keyPrefix: 'steps.buy' });

  const faqs = t('faqs', {
    returnObjects: true,
  }) as FAQ[];

  return (
    <Stack className={styles.buyScreen}>
      <Accordion type="multiple" className={styles.faqs}>
        {faqs.map((item, index) => (
          <Accordion.Item
            className={styles.faqItem}
            key={index}
            value={item.title}
            header={<Text size={'m'}>{item.title}</Text>}
            styleVariant={'faq'}
          >
            {item.body ? (
              <Text size="s" dangerouslySetInnerHTML={{ __html: item.body }} />
            ) : null}
            {item.links
              ? item.links.map((link, lIndex) => (
                  <a
                    className={styles.faqLink}
                    key={lIndex}
                    href={link.href}
                    download={link.download ?? undefined}
                  >
                    {link.icon ? <Icon name={link.icon} size="s" /> : null}
                    <>{link.label}</>
                  </a>
                ))
              : null}
          </Accordion.Item>
        ))}
      </Accordion>

      <SalesTermsForm />
    </Stack>
  );
};
