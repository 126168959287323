import {
  useCreateInsuranceBankIdMandate,
  useCreateInsuranceBsMandate,
  useCreateInsuranceKidMandate,
  useCreateInsuranceCardMandate,
} from '@axo/shared/data-access/hooks';
import type {
  PaymentMandateType,
  CreateBankIdPaymentMandateRequest,
  CreateBsPaymentMandateRequest,
  CreateCardPaymentMandateRequest,
  CreateKidPaymentMandateRequest,
} from '@axo/shared/data-access/types/insurance_payment';

/***/

type UseCreateInsurancePaymentMandateApiImpl =
  | typeof useCreateInsuranceBankIdMandate
  | typeof useCreateInsuranceBsMandate
  | typeof useCreateInsuranceCardMandate
  | typeof useCreateInsuranceKidMandate;

/**
 * Factory for different PaymentMandateType implementations
 *
 * used by `useCreateInsurancePaymentMandate` to handle different `paymentMandateType`s
 */

export const useCreatePaymentMandateApiImpl = (
  paymentMandateType: PaymentMandateType = 'card'
) => {
  const implMap: Record<
    PaymentMandateType,
    UseCreateInsurancePaymentMandateApiImpl
  > = {
    bankid: useCreateInsuranceBankIdMandate,
    bs: useCreateInsuranceBsMandate,
    card: useCreateInsuranceCardMandate,
    kid: useCreateInsuranceKidMandate,
  };

  if (!implMap[paymentMandateType]) {
    throw new Error(`Unsupported payment mandate type: ${paymentMandateType}`);
  }

  return implMap[paymentMandateType];
};

/**
 * Helper
 */

type PaymentMandateDto =
  | CreateBankIdPaymentMandateRequest
  | CreateBsPaymentMandateRequest
  | CreateCardPaymentMandateRequest
  | CreateKidPaymentMandateRequest;

type DtoCreator = (data: CreateInputData) => PaymentMandateDto;

type CreateInputData = {
  InsuranceID: string;
  CustomerID: string;
  PersonID: string;
  MarketCountry: string;
  //
  appUri?: string;
  amount: number;
  accountNumber?: string;
  clearingNumber?: string;
  registrationNumber?: string;
};

const createBankIdDto = (
  data: CreateInputData
): CreateBankIdPaymentMandateRequest => ({
  InsuranceID: data.InsuranceID,
  CustomerID: data.CustomerID,
  PersonID: data.PersonID,
  MarketCountry: data.MarketCountry,
  AccountNumber: data.accountNumber!,
  ClearingNumber: data.clearingNumber!,
  SourceURL: data.appUri!,
});

const createBsDto = (data: CreateInputData): CreateBsPaymentMandateRequest => ({
  InsuranceID: data.InsuranceID,
  CustomerID: data.CustomerID,
  PersonID: data.PersonID,
  MarketCountry: data.MarketCountry,
  Amount: data.amount,
  RegistrationNumber: data.registrationNumber,
  AccountNumber: data.accountNumber,
});

const createCardDto = (
  data: CreateInputData
): CreateCardPaymentMandateRequest => ({
  InsuranceID: data.InsuranceID,
  CustomerID: data.CustomerID,
  PersonID: data.PersonID,
  MarketCountry: data.MarketCountry,
  PaymentURL: '', // TODO
  CompleteURL: data.appUri, // TODO
  UserAgent: '', // TODO
  Amount: data.amount,
});

const createKidDto = (
  data: CreateInputData
): CreateKidPaymentMandateRequest => ({
  InsuranceID: data.InsuranceID,
  CustomerID: data.CustomerID,
  PersonID: data.PersonID,
  MarketCountry: data.MarketCountry,
  CompleteURL: data.appUri, // TODO
  Amount: data.amount,
});

const dtoCreators: Record<PaymentMandateType, DtoCreator> = {
  bankid: createBankIdDto,
  bs: createBsDto,
  card: createCardDto,
  kid: createKidDto,
};

export const createPaymentMandateDto = (
  paymentMandateType: PaymentMandateType = 'card',
  data: any
) => {
  const dtoCreator = dtoCreators[paymentMandateType];

  if (!dtoCreator) {
    throw new Error(`Unsupported payment mandate type: ${paymentMandateType}`);
  }

  return dtoCreator(data);
};
