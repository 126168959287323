import { Stack } from '@axo/ui-core/components/layout/item';
import { Skeleton } from '@axo/ui-core/components/Skeleton';
import React, { useCallback, useEffect, useRef } from 'react';
import type {
  PaymentProviderImpl,
  PaymentProviderProps,
} from '../../PaymentProvider.types';
import { IframeWrapper } from '../IframeWrapper';
import styles from './bankIdPaymentProvider.module.scss';
import { useBankIdPaymentProvider } from './useBankIdPaymentProvider';

export const BankIdPaymentProvider: PaymentProviderImpl = ({
  data,
  ...callbacks
}: PaymentProviderProps = {}) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const contentStateRef = useRef<'loading' | 'idle'>('idle');

  const { onViewReady, onStartPayment, isLoaded, isInitialized, isStarted } =
    useBankIdPaymentProvider({ data, ...callbacks });

  const onLoadIframe = (iframe: HTMLIFrameElement) => {
    iframeRef.current = iframe;

    const isDesktop = false; // TODO iframe only on desktop, on mobile it needs the uri to switch back from the mobile app ...

    onViewReady(isDesktop ? iframe.contentDocument! : document);
  };

  const handleIframeMessage = useCallback((message: any) => {
    console.log('Iframe message', message);
  }, []);

  useEffect(() => {
    if (!isInitialized) contentStateRef.current = 'loading';
    if (isInitialized) contentStateRef.current = 'idle';
  }, [contentStateRef.current, isLoaded, onStartPayment, isInitialized]);

  return (
    <Stack
      className={styles.bankIdPaymentProvider}
      data-content-state={contentStateRef.current}
    >
      <Skeleton className={styles.skeleton} />
      <IframeWrapper
        onLoad={onLoadIframe}
        onMessage={handleIframeMessage}
        className={styles.iframe}
      />
    </Stack>
  );
};
