import type { Variants } from 'motion/react';

const durationFilterOpacity = 0.18;

export const enabledStateAnimations: Variants = {
  enabled: {
    filter: 'none',
    opacity: 1,
    transition: { duration: durationFilterOpacity },
  },
  disabled: {
    filter: 'grayscale(100%)',
    opacity: 0.6,
    transition: { duration: durationFilterOpacity },
  },
};

const durationHeight = 0.1;
const durationOpacity = 0.08;

export const heightAnimation: Variants = {
  initial: {
    height: 0,
    opacity: 0,
  },
  visible: {
    height: 'auto',
    opacity: 1,
    visibility: 'visible',
    transition: {
      height: { duration: durationHeight, ease: 'easeInOut' },
      opacity: { duration: durationOpacity, delay: durationHeight },
    },
  },
  hidden: {
    height: 0,
    opacity: 0,
    transition: {
      height: {
        duration: durationHeight,
        ease: 'easeInOut',
      },
      opacity: { duration: durationOpacity },
    },
    transitionEnd: {
      visibility: 'hidden',
    },
  },
};

const durationSlide = 0.2;

type customSlideArgs = { direction: number; containerWidth: number };

export const slideAnimation: Variants = {
  enter: ({ direction, containerWidth }: customSlideArgs) => ({
    x: direction > 0 ? containerWidth : -containerWidth,
    transition: { duration: durationSlide, ease: 'easeInOut' },
  }),
  center: {
    x: 0,
    transition: { duration: durationSlide, ease: 'easeInOut' },
  },
  exit: ({ direction, containerWidth }: customSlideArgs) => ({
    x: direction < 0 ? containerWidth : -containerWidth,
    transition: { duration: durationSlide, ease: 'easeInOut' },
  }),
};

const durationFade = 0.3;
const durationStepsHeight = 0.32;

export const fadeAnimation: Variants = {
  enter: {
    opacity: 0,
    transition: {
      duration: durationFade,
      delay: durationFade,
      ease: 'easeInOut',
    },
  },
  center: {
    opacity: 1,
    transition: {
      duration: durationFade,
      delay: durationFade,
      ease: 'easeInOut',
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: durationFade, ease: 'easeInOut' },
  },
};

export const stepsHeightAnimation: Variants = {
  initial: {
    height: 0,
  },
  // animate: {
  //   height: 'auto',
  //   transition: {
  //     duration: durationStepsHeight,
  //     delay: durationFade,
  //     ease: 'easeInOut',
  //   },
  // },
  animate: (height) => ({
    height: height,
    transition: {
      delay: durationStepsHeight / 4,
      duration: durationStepsHeight,
      ease: 'easeInOut',
    },
    // TODO verify
    // transitionEnd: {
    //   height: 'auto',
    // },
  }),
};
