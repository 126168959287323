import { IAcceptQuote } from '@axo/mypage/data-access';
import { AcceptModal } from '@axo/mypage/ui/atom';
import { breadcrumbs } from '@axo/mypage/util';
import { useTranslation } from '@axo/mypage/util/translation';
import {
  EventCode,
  KnownResourceType,
  useEventLogger,
  useEventResource,
} from '@axo/shared/data-access/event-log';
import { loan_quote_presentation } from '@axo/shared/data-access/types';
import { MarketCountryCode } from '@axo/shared/types';
import { LenderLogo } from '@axo/shared/ui/atoms/LenderLogo';
import { Button } from '@axo/ui-core/components/Button';
import { Icon } from '@axo/ui-core/components/Icon';
import { Stack } from '@axo/ui-core/components/layout/item';
import { Heading, Text } from '@axo/ui-core/components/typography';
import { useCallback, useEffect, useMemo } from 'react';
import styles from './ChangeAcceptOfferModal.module.scss';

const acceptModalClasses = {
  root: styles.modalRoot,
  content: styles.modalContent,
  actionBar: { root: styles.actionContainer, buttons: styles.buttons },
};

type ChangeAcceptOfferModalProps = {
  oldOffer: loan_quote_presentation.EnrichedLoanQuote;
  newOffer: loan_quote_presentation.PresentedLoanQuote;
  acceptableQuoteIndex: number;
  onChangeOffer: (
    offer: loan_quote_presentation.PresentedLoanQuote,
    acceptableIndex: number
  ) => Promise<void>;
  marketCountry: string;
  closeModal: () => void;
  accept: IAcceptQuote;
};

export function ChangeAcceptOfferModal({
  oldOffer,
  newOffer,
  acceptableQuoteIndex,
  marketCountry,
  accept,
  closeModal,
  onChangeOffer,
}: ChangeAcceptOfferModalProps) {
  const { t } = useTranslation();
  const oldLender = oldOffer.Lender;
  const newLender = newOffer.Lender;
  const log = useEventLogger(
    ...breadcrumbs(newOffer),
    ...breadcrumbs(oldOffer)
  );

  useEventResource(KnownResourceType.Lender, newOffer.LenderID);
  useEventResource(KnownResourceType.LoanRequest, newOffer.LoanRequestID);
  useEventResource(KnownResourceType.LoanQuote, newOffer.ID);
  useEventResource(KnownResourceType.Lender, oldOffer.LenderID);
  useEventResource(KnownResourceType.LoanRequest, oldOffer.LoanRequestID);
  useEventResource(KnownResourceType.LoanQuote, oldOffer.ID);

  useEffect(() => {
    log(EventCode.OpenChangeOfferModal, { newQuoteID: newOffer.ID });
  }, [log, newOffer, oldOffer]);

  const handleAccept = useCallback(async () => {
    await log(EventCode.ChangeOffer, { newQuoteID: newOffer.ID });
    await onChangeOffer(newOffer, acceptableQuoteIndex);
  }, [acceptableQuoteIndex, log, newOffer, onChangeOffer]);

  const onClose = useCallback(() => {
    log(EventCode.CloseChangeOfferModal, { newQuoteID: newOffer.ID });
    closeModal();
  }, [closeModal, log, newOffer]);

  const isButtonHeaderVisible = useMemo(
    () => marketCountry === MarketCountryCode.Sweden,
    [marketCountry]
  );

  const acceptModalActionBar = useMemo(
    () => ({
      title: isButtonHeaderVisible
        ? t('Are you sure you want to change your selection?')
        : undefined,
      buttons: (
        <>
          <Button variant={'tertiary'} size={'small'} onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button
            size={'small'}
            onClick={handleAccept}
            loading={accept.isLoading}
            disabled={accept.isLoading}
          >
            {t('Confirm and proceed')}
          </Button>
        </>
      ),
    }),
    [accept.isLoading, handleAccept, onClose, t, isButtonHeaderVisible]
  );

  return (
    <AcceptModal
      open={!!oldOffer && !!newOffer}
      onClose={onClose}
      actionBar={acceptModalActionBar}
      classes={acceptModalClasses}
      type={'info'}
    >
      <Heading size={'s'}>{t('Change your selection')}</Heading>
      <Text>{t('You are changing your loan offer')}</Text>
      <Stack direction={'h'} className={styles.lenders}>
        <Stack gap={'2xs'} className={styles.lenderLogoContainer}>
          <div className={styles.lenderLogo}>
            {oldLender && (
              <LenderLogo lender={oldLender} marketCountry={marketCountry} />
            )}
          </div>
          <Text>
            <b>{t('Currently selected')}</b>
          </Text>
        </Stack>
        <Icon name="arrow-fat-line-right-solid" />
        <Stack gap={'2xs'} className={styles.lenderLogoContainer}>
          <div className={styles.lenderLogo}>
            {newLender && (
              <LenderLogo lender={newLender} marketCountry={marketCountry} />
            )}
          </div>
          <Text>
            <b>{t('New selection')}</b>
          </Text>
        </Stack>
      </Stack>
    </AcceptModal>
  );
}
