import { BrandInsurance } from '@axo/ui-core/components/Brand';
import { Button } from '@axo/ui-core/components/Button';
import { Icon } from '@axo/ui-core/components/Icon';
import type { Country } from '@axo/ui-core/i18n/@types/Country.types';
import type { ReactNode } from 'react';
import { useViewStateMachine } from '../../store/useViewStateMachine';

import styles from './header.module.scss';

type HeaderProps = {
  children?: ReactNode;
  country: Country;
  canStepForward?: boolean;
};

/**
 * Header
 */
export const Header = ({
  children,
  country = 'SE',
  canStepForward = false,
}: HeaderProps) => {
  const { isFirstStep, isLastStep, goToPreviousStep, goToNextStep } =
    useViewStateMachine();

  return (
    <div className={styles.header}>
      <div
        className={styles.nav}
        data-is-first-step={isFirstStep || undefined}
        data-is-last-step={isLastStep || undefined}
        data-can-step-forward={canStepForward || undefined}
      >
        <Button
          variant="ghost"
          size="small"
          iconOnly
          aria-label="back"
          onClick={goToPreviousStep}
          className={styles.btnBack}
        >
          <Icon name="arrow-left" />
        </Button>
        <BrandInsurance
          country={country}
          variant={'with-provider'}
          className={styles.brand}
        />
        <Button
          variant="ghost"
          size="small"
          iconOnly
          aria-label="forward"
          onClick={goToNextStep}
          className={styles.btnForward}
        >
          <Icon name="arrow-right" />
        </Button>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
