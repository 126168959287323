import { Heading, Text } from '@axo/ui-core/components/typography';
import styles from './HighCostWarning.module.scss';

export const HighCostWarning = () => {
  return (
    <div className={styles.warning}>
      <div className={styles.heading}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="33"
          viewBox="0 0 32 33"
        >
          <path
            fill="currentColor"
            d="M2.36 30.44h1.74c.59 0 1.06-.48 1.06-1.06s-.47-1.06-1.06-1.06H2.36c-.13 0-.19-.07-.21-.11-.04-.08-.01-.13.01-.17L15.61 5.08c.1-.06.36-.06.45 0l2.47 4.12c.3.5.96.67 1.46.37.5-.3.67-.96.37-1.46L17.87 3.96c-.4-.66-1.16-1.05-2.03-1.05h-.02c-.88.01-1.63.41-2.04 1.08L.32 26.99c-.42.71-.42 1.56.01 2.27.42.72 1.21 1.18 2.04 1.18ZM9.47 30.44h20.17c.86 0 1.65-.46 2.06-1.19.41-.72.4-1.57-.03-2.28l-8.9-14.68c-.3-.5-.96-.67-1.46-.37-.5.3-.66.96-.36 1.46l8.9 14.68c.01.03.03.04.01.08-.02.04-.08.11-.23.11H9.47c-.59 0-1.06.48-1.06 1.06s.47 1.06 1.06 1.06ZM15.84 21.59c-.7 0-1.27-.57-1.27-1.27v-6.93c0-.7.57-1.27 1.27-1.27.7 0 1.27.57 1.27 1.27v6.93c0 .7-.57 1.27-1.27 1.27ZM14.48 24.33c0 .7.57 1.27 1.27 1.27.7 0 1.27-.57 1.27-1.27v-.09c0-.7-.57-1.27-1.27-1.27-.7 0-1.27.57-1.27 1.27v.09Z"
          />
        </svg>
        <Heading size={'xxs'}>Det här är en högkostnadskredit</Heading>
      </div>
      <Text size="s">
        Om du inte kan betala tillbaka hela skulden riskerar du en
        betalningsanmärkning. För stöd, vänd dig till budget- och
        skuldrådgivningen i din kommun. Kontaktuppgifter finns på{' '}
        <a href="https://www.hallakonsument.se">hallakonsument.se</a>.
      </Text>
    </div>
  );
};
