import { applicationKeys } from '@axo/shared/data-access/hooks';
import {
  ApplicationWSData,
  CustomerEventType,
} from '@axo/shared/data-access/types';
import { LoanApplicationContext } from '@axo/shared/feature/providers';
import {
  IWebSocketData,
  useWSSubscriber,
} from '@axo/shared/feature/web-socket';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { useQueryClient } from 'react-query';
import { ApplicationProgressContext } from '../ApplicationProgress';

// Set 30 seconds as recovery timeout
const RECOVERY_TIMEOUT = 1000 * 30;
const onRecoveryTimout = (callback: () => void) =>
  setInterval(callback, RECOVERY_TIMEOUT);

export function WebSocketSubscriber() {
  const client = useQueryClient();

  const {
    state: { application },
  } = useContext(LoanApplicationContext);
  const {
    state: { offers, progress, allBanksResponded },
    dispatch,
  } = useContext(ApplicationProgressContext);

  const isPollingDisabled = allBanksResponded || progress === 100;

  if (application?.ID === '') throw new Error('Missing applicationID');

  const applicationKey = useMemo(
    () =>
      applicationKeys.root({
        applicationID: application?.ID,
      }),
    [application]
  );

  const invalidateQueries = () => {
    client.invalidateQueries(applicationKey);
  };

  const recoveryIntervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!isPollingDisabled) {
      recoveryIntervalRef.current = onRecoveryTimout(invalidateQueries);
    }

    return () => {
      if (recoveryIntervalRef.current) {
        clearInterval(recoveryIntervalRef.current);
        recoveryIntervalRef.current = null;
      }
    };
  }, [isPollingDisabled]);

  const handleWebSocketMessage = (data: IWebSocketData) => {
    const messageData = data.latestMessage as unknown as ApplicationWSData;

    // Restart recovery timout
    if (recoveryIntervalRef.current) {
      clearInterval(recoveryIntervalRef.current);
      if (!isPollingDisabled) {
        recoveryIntervalRef.current = onRecoveryTimout(invalidateQueries);
      }
    }

    dispatch({
      type: 'Set progress event',
      payload: {
        name: messageData.Event,
        humanReadableID: messageData.ApplicationHumanReadableID,
      },
    });

    if (
      application?.HumanReadableID === messageData.ApplicationHumanReadableID
    ) {
      if (
        offers.completed < offers.total &&
        messageData.Event !== CustomerEventType.QuoteApproved &&
        messageData.Event !== CustomerEventType.StepsCompleted
      ) {
        dispatch({
          type: 'Set progress offers',
          payload: {
            ...offers,
            completed: offers.completed + 1,
          },
        });
      } else {
        invalidateQueries();
      }
    }
  };

  const subscriptions = [
    {
      source: 'loan-quote',
      subscriberCallback: invalidateQueries,
    },
    {
      source: CustomerEventType.QuoteApproved,
      code: CustomerEventType.QuoteApproved,
      subscriberCallback: handleWebSocketMessage,
    },
    {
      source: CustomerEventType.QuoteCancelled,
      code: CustomerEventType.QuoteCancelled,
      subscriberCallback: handleWebSocketMessage,
    },
    {
      source: CustomerEventType.StepAdvanced,
      code: CustomerEventType.StepAdvanced,
      subscriberCallback: handleWebSocketMessage,
    },
  ];

  useWSSubscriber(subscriptions);

  return null;
}
