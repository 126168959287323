import { clsx } from 'clsx';
import { ReactElement, ReactNode } from 'react';
import { InputSizeVariant } from '../../../input/InputProps.types';

import styles from './formControlCaption.module.scss';

type FormControlCaptionProps = {
  children?: ReactNode;
  className?: string;
  size?: InputSizeVariant;
} & HTMLDataAttributes;

/**
 * FormControlCaption component
 */
export const FormControlCaption = ({
  children,
  className,
  size = 'small',
  ...props
}: FormControlCaptionProps): ReactElement => {
  return (
    <div
      className={clsx(styles.formControlCaption, className)}
      data-size={size}
      data-form-control-element="form-control-caption"
      {...props}
    >
      {children}
    </div>
  );
};
