import { ReactElement } from 'react';
import {
  InputLabel,
  InputLabelProps,
} from '../../../input/_elements/InputLabel/InputLabel';

import styles from './formControlLabel.module.scss';

type FormControlLabelProps = InputLabelProps;

/**
 * FormControlLabel component
 */
export const FormControlLabel = ({
  children,
  ...props
}: FormControlLabelProps): ReactElement => {
  return (
    <InputLabel
      className={styles.formControlLabel}
      data-form-control-element="form-control-label"
      {...props}
    >
      {children}
    </InputLabel>
  );
};
