import type {
  ServicesBaseURL} from '@axo/shared/data-access/provider';
import {
  DataAccessProvider
} from '@axo/shared/data-access/provider';
import type { ReactNode } from 'react';
import React from 'react';
import { QueryClient } from 'react-query';
import { ConfigService } from '../../config/ConfigService';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Disable refetching on window focus, as there is no risk of mutating the instance in another session
      refetchOnWindowFocus: false,
    },
  },
});

type DataAccessProviderFromLibsProps = {
  children: ReactNode;
};

export const DataAccessProviderFromLibs = ({
  children,
}: DataAccessProviderFromLibsProps) => {
  const servicesURL: ServicesBaseURL = {
    api: ConfigService.config.services.apiUrl,
  };

  return (
    <DataAccessProvider queryClient={queryClient} url={servicesURL}>
      {children}
    </DataAccessProvider>
  );
};
