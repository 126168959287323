import * as RadixSlider from '@radix-ui/react-slider';
import { clsx } from 'clsx';
import { ReactElement } from 'react';

import styles from './slider.module.scss';

type SliderProps = {
  name?: string;
  defaultValue?: number[];
  value?: number[];
  min?: number;
  max?: number;
  step?: number;
  minStepsBetweenThumbs?: number;

  onValueChange?: (value: number[]) => void;
  onValueCommit?: (value: number[]) => void;

  disabled?: boolean;
  className?: string;
} & HTMLDataAttributes;

/**
 * Slider component
 *
 * @see https://www.radix-ui.com/primitives/docs/components/slider
 */
export const Slider = ({
  className,
  name,
  defaultValue,
  value,
  min = 0,
  max = 100,
  step = 1,
  minStepsBetweenThumbs,
  onValueChange,
  onValueCommit,
  disabled,
  ...dataProps
}: SliderProps): ReactElement => {
  const sliderProps = {
    name,
    defaultValue,
    value,
    min,
    max,
    step,
    minStepsBetweenThumbs,
    onValueChange,
    onValueCommit,
    disabled,
  };

  return (
    <div className={clsx(styles.slider, className)} {...dataProps}>
      <RadixSlider.Root className={styles.root} {...sliderProps}>
        <RadixSlider.Track className={styles.track}>
          <RadixSlider.Range className={styles.range} />
        </RadixSlider.Track>
        <RadixSlider.Thumb className={styles.thumb} aria-label="value" />
      </RadixSlider.Root>
    </div>
  );
};
