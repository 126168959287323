import { QuoteState } from '@axo/mypage/ui/atom';
import { MyPageFlags } from '@axo/mypage/util';
import { useGoToBank } from '@axo/mypage/util/dom';
import {
  loan_quote,
  loan_quote_presentation,
} from '@axo/shared/data-access/types';
import { useTypedFlags } from '@axo/shared/services/feature-flags';
import { ListItem } from '../../ListItem/ListItem';

export const SelectableLoanQuotes = ({
  acceptedLoanQuotes,
  acceptableLoanQuotes,
  acceptQuote,
  isLoading,
  acceptedQuote,
  marketCountry,
  disableUserAccept,
  offersUnlocked,
}: {
  acceptedLoanQuotes: loan_quote_presentation.EnrichedLoanQuote[];
  acceptableLoanQuotes: loan_quote_presentation.PresentedLoanQuote[];
  acceptQuote: (
    offer: loan_quote_presentation.PresentedLoanQuote,
    acceptableIndex: number
  ) => Promise<void>;
  isLoading: boolean;
  acceptedQuote: loan_quote_presentation.EnrichedLoanQuote | null;
  marketCountry: string;
  disableUserAccept?: boolean;
  offersUnlocked?: boolean;
}) => {
  const { goToBank } = useGoToBank({
    quote: acceptedQuote ?? undefined,
  });
  const previouslyAcceptedQuotes = acceptedLoanQuotes.slice(1);
  const {
    flag_show_insurance_on_accept,
    flag_show_recommended_offer_after_accept,
    flag_show_recommended_offer_on_credit_card,
  } = useTypedFlags(MyPageFlags);

  return (
    <>
      {previouslyAcceptedQuotes.map((quote) => (
        <ListItem
          key={quote.ID}
          quote={quote}
          state={QuoteState.PreviouslySelected}
          action={goToBank}
          marketCountry={marketCountry}
          offersUnlocked={offersUnlocked}
        />
      ))}
      {acceptableLoanQuotes.map((quote, index) => (
        <ListItem
          key={quote.ID}
          quote={quote}
          state={
            quote.Recommended &&
            (quote.Product === loan_quote.Product.UnsecuredLoan ||
              flag_show_recommended_offer_on_credit_card) &&
            (!acceptedLoanQuotes.length ||
              flag_show_recommended_offer_after_accept) &&
            offersUnlocked
              ? QuoteState.Recommended
              : QuoteState.None
          }
          isLoadingAction={
            flag_show_insurance_on_accept
              ? isLoading && acceptedQuote?.ID === quote.ID
              : false
          }
          action={() => acceptQuote(quote, index)}
          marketCountry={marketCountry}
          disableUserAccept={disableUserAccept}
          offersUnlocked={offersUnlocked}
        />
      ))}
    </>
  );
};
