import { create } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { loan_application } from '@axo/shared/data-access/types';

import {
  ApplicationData,
  DataStore,
  DataStoreState,
  RequestState,
} from './data-store.types';

const initialRequestState: RequestState = {
  data: null,
  status: 'idle',
  error: null,
};

const initialState: DataStoreState = {
  auth: {
    token: null,
    magicToken: null,
  },
  application: {
    loan: { ...initialRequestState },
    input: null,
  },
};

const useDataStore = create<DataStore>()(
  subscribeWithSelector(
    devtools(
      (set) => ({
        ...initialState,

        setMagicToken: (magicToken: string) =>
          set(
            (state) => ({ auth: { ...state.auth, magicToken } }),
            undefined,
            'data/setMagicToken'
          ),
        setToken: (token: string) =>
          set(
            (state) => ({ auth: { ...state.auth, token } }),
            undefined,
            'data/setToken'
          ),
        setLoan: (loan: Partial<loan_application.LoanApplication>) =>
          set(
            (state) => ({
              application: {
                ...state.application,
                loan: { ...state.application.loan, data: loan },
              },
            }),
            undefined,
            'data/setLoan'
          ),
        setRequestState: (
          key: keyof ApplicationData,
          state: Partial<RequestState>
        ) =>
          set(
            (existingState) => ({
              application: {
                ...existingState.application,
                [key]: {
                  ...(existingState.application[key] as RequestState),
                  ...state,
                },
              },
            }),
            undefined,
            `data/set${key.charAt(0).toUpperCase() + key.slice(1)}`
          ),
        setInput: (input: Partial<ApplicationData['input']> | null) =>
          set(
            (state) => ({
              application: {
                ...state.application,
                input: input ? { ...state.application.input, ...input } : null,
                loan: { ...state.application.loan, status: 'requested' },
              },
            }),
            undefined,
            'data/setInput'
          ),
      }),
      { name: 'account-number/data-store' }
    )
  )
);

export { useDataStore };
