import { Stack } from '@axo/ui-core/components/layout/item';
import { clsx } from 'clsx';
import { AnimatePresence, motion } from 'motion/react';
import { ReactNode, useEffect } from 'react';
import { areaAnimations } from './ContentLayout.animation';

import styles from './contentLayout.module.scss';

import { useViewStateMachine } from './store/useViewStateMachine';
import {
  ViewArea,
  ViewSequenceConfigType,
} from './store/view-state-machine.types';

type CommonContentLayoutProps = {
  children: ReactNode;
  className?: string;
} & HTMLDataAttributes;

export type ContentLayoutProps = CommonContentLayoutProps & {
  sequence: ViewSequenceConfigType;
};

const ContentLayoutRoot = ({
  children,
  className,
  sequence = 'static',
  ...props
}: ContentLayoutProps) => {
  const setSequence = useViewStateMachine((state) => state.setSequence);
  useEffect(() => setSequence(sequence), [sequence, setSequence]);

  return (
    <div
      className={clsx(styles.contentLayout, className)}
      data-view-sequence={sequence}
      {...props}
    >
      {children}
    </div>
  );
};

type ContentLayoutAreaProps = CommonContentLayoutProps & {
  area: ViewArea;
};

const ContentLayoutArea = ({
  children,
  className,
  area,
  ...props
}: ContentLayoutAreaProps) => {
  const areaConfig = useViewStateMachine((state) => state.areas?.[area]);

  return (
    <AnimatePresence initial={false}>
      <motion.div
        className={clsx(styles.contentLayout__area, className)}
        data-area={area}
        data-visible={areaConfig?.visible || false}
        data-focus={areaConfig?.focus ?? undefined}
        data-sticky={areaConfig?.sticky ?? undefined}
        initial={'hidden'}
        animate={areaConfig?.visible ? 'visible' : 'hidden'}
        exit={'exit'}
        variants={areaAnimations}
        {...props}
      >
        <div className={styles.areaContent}>{children}</div>
      </motion.div>
    </AnimatePresence>
  );
};

export const ContentLayout = Object.assign(ContentLayoutRoot, {
  Area: ContentLayoutArea,
});
